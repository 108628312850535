.w-checkbox {
	display: block;
	margin-bottom: 5px;
	padding-left: 20px;
}

.w-checkbox::before {
	content: ' ';
	display: table;
	-ms-grid-column-span: 1;
	grid-column-end: 2;
	-ms-grid-column: 1;
	grid-column-start: 1;
	-ms-grid-row-span: 1;
	grid-row-end: 2;
	-ms-grid-row: 1;
	grid-row-start: 1;
}

.w-checkbox::after {
	content: ' ';
	display: table;
	-ms-grid-column-span: 1;
	grid-column-end: 2;
	-ms-grid-column: 1;
	grid-column-start: 1;
	-ms-grid-row-span: 1;
	grid-row-end: 2;
	-ms-grid-row: 1;
	grid-row-start: 1;
	clear: both;
}

.w-checkbox-input {
	float: left;
	margin-bottom: 0px;
	margin-left: -20px;
	margin-right: 0px;
	margin-top: 4px;
	line-height: normal;
}

.w-checkbox-input--inputType-custom {
	border-top-width: 1px;
	border-bottom-width: 1px;
	border-left-width: 1px;
	border-right-width: 1px;
	border-top-color: #ccc;
	border-bottom-color: #ccc;
	border-left-color: #ccc;
	border-right-color: #ccc;
	border-top-style: solid;
	border-bottom-style: solid;
	border-left-style: solid;
	border-right-style: solid;
	width: 12px;
	height: 12px;
	border-bottom-left-radius: 2px;
	border-bottom-right-radius: 2px;
	border-top-left-radius: 2px;
	border-top-right-radius: 2px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
	background-color: #3898ec;
	border-top-color: #3898ec;
	border-bottom-color: #3898ec;
	border-left-color: #3898ec;
	border-right-color: #3898ec;
	background-image: url('https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
	box-shadow: 0px 0px 3px 1px #3898ec;
}

.w-form-formradioinput--inputType-custom {
	border-top-width: 1px;
	border-bottom-width: 1px;
	border-left-width: 1px;
	border-right-width: 1px;
	border-top-color: #ccc;
	border-bottom-color: #ccc;
	border-left-color: #ccc;
	border-right-color: #ccc;
	border-top-style: solid;
	border-bottom-style: solid;
	border-left-style: solid;
	border-right-style: solid;
	width: 12px;
	height: 12px;
	border-bottom-left-radius: 50%;
	border-bottom-right-radius: 50%;
	border-top-left-radius: 50%;
	border-top-right-radius: 50%;
}

.w-form-formradioinput--inputType-custom.w--redirected-focus {
	box-shadow: 0px 0px 3px 1px #3898ec;
}

.w-form-formradioinput--inputType-custom.w--redirected-checked {
	border-top-width: 4px;
	border-bottom-width: 4px;
	border-left-width: 4px;
	border-right-width: 4px;
	border-top-color: #3898ec;
	border-bottom-color: #3898ec;
	border-left-color: #3898ec;
	border-right-color: #3898ec;
}

body {
	font-family: Montserrat, sans-serif;
	color: #404040;
	font-size: 1rem;
	line-height: 1.6em;
}

h1 {
	margin-top: 1.5rem;
	margin-bottom: 1rem;
	font-family: Merriweather, serif;
	color: #662c2c;
	font-size: 2rem;
	line-height: 1.5em;
	font-weight: 400;
}

h2 {
	margin-top: 1.5rem;
	margin-bottom: 0.7rem;
	font-family: Merriweather, serif;
	color: #662c2c;
	font-size: 1.75rem;
	line-height: 1.5em;
	font-weight: 400;
}

h3 {
	margin-top: 1.5rem;
	margin-bottom: 0.7rem;
	font-family: Merriweather, serif;
	color: #00788d;
	font-size: 1.5rem;
	line-height: 1.5em;
	font-weight: 400;
}

h4 {
	margin-top: 1.25rem;
	margin-bottom: 0.7rem;
	font-family: Merriweather, serif;
	color: #00788d;
	font-size: 1.25rem;
	line-height: 1.6em;
	font-weight: 400;
}

h5 {
	margin-top: 1.25rem;
	margin-bottom: 0.7rem;
	font-family: Merriweather, serif;
	color: #00788d;
	font-size: 1rem;
	line-height: 1.6em;
	font-weight: 700;
}

h6 {
	margin-top: 1.25rem;
	margin-bottom: 0.5rem;
	font-family: Merriweather, serif;
	color: #00788d;
	font-size: 0.9rem;
	line-height: 1.6em;
	font-weight: 700;
	text-transform: uppercase;
}

p {
	margin-top: 0.5rem;
	margin-bottom: 1rem;
	font-size: 1rem;
	font-weight: 400;
}

a {
	color: #404040;
	text-decoration: underline;
}

ul {
	margin-top: 0px;
	margin-bottom: 1rem;
	padding-left: 40px;
}

ol {
	margin-top: 0px;
	margin-bottom: 10px;
	padding-left: 40px;
}

label {
	display: block;
	margin-bottom: 5px;
	line-height: 1.5rem;
	font-weight: 700;
}

blockquote {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	padding: 1.5rem 1.25rem;
	border-left: 5px solid #005969;
	background-color: rgba(138, 187, 181, 0.2);
	color: #312f2f;
	font-size: 1.143rem;
	line-height: 1.843rem;
}

.button {
	display: inline-block;
	height: 44px;
	min-width: 100px;
	margin-top: 1rem;
	margin-right: 1.5rem;
	margin-bottom: 1rem;
	padding: 9px 1rem 10px;
	border-style: solid;
	border-width: 1px;
	border-color: #193885;
	border-radius: 0px;
	background-color: #193885;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Lato, sans-serif;
	color: #fff;
	line-height: 1.6rem;
	font-weight: 600;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
}

.button:hover {
	background-color: #193885;
	background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0.1)), to(hsla(0, 0%, 100%, 0.1)));
	background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.1), hsla(0, 0%, 100%, 0.1));
}

.button.secondary {
	border-style: solid;
	border-width: 1px;
	border-color: #193885;
	background-color: transparent;
	font-family: Lato, sans-serif;
	color: #312f2f;
	font-weight: 700;
	text-align: center;
}

.button.secondary:hover {
	border-color: #650030;
	background-color: #650030;
	background-image: none;
	color: #fff;
}

.button.search-button {
	width: 30%;
	height: 2.5rem;
	min-height: 2.5rem;
	margin-top: 0rem;
	margin-right: 0rem;
	margin-bottom: 0rem;
	padding: 0.38rem 1rem;
	float: left;
}

.button.search-button.search-top-button {
	width: 10%;
	height: 44px;
	background-color: #dadada;
	font-family: Lato, sans-serif;
	color: #312f2f;
	font-weight: 700;
}

.button.sidebar-button {
	display: none;
	height: 36px;
	min-width: 0px;
	margin-top: 0.5rem;
	margin-right: 0rem;
	margin-bottom: 0.5rem;
	padding: 0.35rem 0.75rem;
	background-color: #612323;
	font-size: 0.9rem;
	line-height: 1.4rem;
}

.button.sidebar-button:hover {
	background-color: #501818;
}

.button.court-search-button {
	width: 25%;
	height: 44px;
	margin-top: 0rem;
	margin-right: 0rem;
	margin-bottom: 0rem;
	background-color: #c09f80;
	color: #312f2f;
}

.button.court-search-button:hover {
	background-color: #ad8a69;
	background-image: none;
	color: #fff;
}

.button.tertiary {
	height: 44px;
	min-width: 0px;
	margin-top: 0.5rem;
	margin-right: 0rem;
	margin-bottom: 0.5rem;
	padding: 9px 1rem 10px;
	background-color: #ccccca;
	color: #312f2f;
	font-size: 1rem;
	line-height: 1.6rem;
	font-weight: 700;
}

.button.tertiary:hover {
	background-color: #afafae;
	color: #fff;
}

.main-content-wrapper {
	width: 100%;
	padding-top: 0.7rem;
	float: left;
	font-family: Lato, sans-serif;
}

.text-styles {
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.container {
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
}

.container.inside-pg-content-container {
	display: block;
	padding-right: 2%;
	padding-left: 2%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: #fff;
}

.container.footer-container {
	display: block;
	padding: 3rem 2rem 1.5rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	border-top: 5px solid #cec1b7;
}

.container.top-cbs-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: none;
	background-color: #fff;
}

.container.lower-container {
	height: 100%;
}

.container.page-title-container {
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	border-top: 5px solid #cec1b7;
	background-image: url('/20181016082423/assets/images/blu-stripes_1blu-stripes.png');
	background-position: 0px 0px;
	background-size: 50px;
	text-align: center;
}

.left-nav-col {
	width: 20%;
	height: 100%;
	margin-right: 3%;
	padding-top: 2.25rem;
	float: left;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}

.inside-content-column {
	width: 80%;
	float: left;
}

.inside-content-column.right-sidebar-only-content-column {
	width: 100%;
}

.inside-content-column.no-sidebars-content-column {
	display: block;
	width: 100%;
	margin-right: 15px;
	margin-left: 15px;
}

.breadcrumbs-wrapper {
	margin-top: 1rem;
	font-family: Lato, sans-serif;
	font-size: 0.8rem;
	line-height: 1.5rem;
}

.breadcrumb-link {
	padding-bottom: 2px;
	border-bottom: 1px solid rgba(25, 56, 133, 0.5);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #525252;
	font-size: 0.8rem;
	line-height: 1.5rem;
	text-decoration: none;
}

.breadcrumb-link:hover {
	border-bottom-color: transparent;
}

.breadcrumb-link.current-bc-link {
	border-style: none;
	color: #193885;
}

.breadcrumb-current {
	display: inline-block;
	color: #07254b;
}

.content-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	font-family: Lato, sans-serif;
	min-height: 500px;
}

.right-side-col {
	width: 21%;
	margin-left: 4%;
	padding-top: 0.125rem;
	float: left;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	font-family: Lato, sans-serif;
}

.right-side-col h2 {
	margin-top: 1.25rem;
	margin-bottom: 0.5rem;
	font-family: Lato, sans-serif;
	color: #193885;
	font-size: 1.143rem;
	line-height: 1.743rem;
}

.right-side-col p {
	font-size: 0.95rem;
	line-height: 1.45rem;
}

.right-side-col h3 {
	margin-top: 1.25rem;
	margin-bottom: 0.5rem;
	font-family: Lato, sans-serif;
	color: #193885;
	font-size: 1.143rem;
	line-height: 1.743rem;
}

.right-side-col h4 {
	margin-bottom: 0.5rem;
	font-family: Lato, sans-serif;
	color: #193885;
	font-size: 1.143rem;
	line-height: 1.743rem;
}

.left-nav-section-title {
	margin-bottom: 0rem;
	font-size: 1.5rem;
	text-decoration: none;
}

.left-nav-section-title-link,
.left-nav-section-title-link:hover {
	display: block;
	padding-left: 0.35rem;
	border-bottom: 1px dashed rgba(137, 152, 158, 0.75);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Lato, sans-serif;
	color: #193885;
	font-size: 1.125rem;
	font-weight: 500;
	text-decoration: none;
	text-transform: uppercase;
}

.left-nav-list {
	margin-bottom: 0rem;
}

.left-nav-list-item {
	margin-bottom: 2px;
	padding-right: 0.5rem;
}

.left-nav-list-item.grandchild-li {
	margin-bottom: 2px;
	margin-left: 1rem;
}

.left-nav-list-item.greatgrandchild-li {
	margin-left: 1rem;
	font-size: 0.9rem;
}

.left-nav-link {
	display: block;
	padding-top: 0.4rem;
	padding-bottom: 0.4rem;
	padding-left: 0.35rem;
	border-bottom: 1px dashed rgba(137, 152, 158, 0.75);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Lato, sans-serif;
	color: #525252;
	font-size: 1rem;
	line-height: 1.7rem;
	font-weight: 400;
	text-decoration: none;
}

.left-nav-link:hover {
	-webkit-transform: translate(10px, 0px);
	-ms-transform: translate(10px, 0px);
	transform: translate(10px, 0px);
	color: #193885;
}

.left-nav-link.w--current {
	box-shadow: -3px 0 0 0 #193885;
	font-family: Lato, sans-serif;
	color: #193885;
}

.left-nav-link.w--current:hover {
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.left-nav-link.grandchild-link {
	padding-left: 0rem;
	font-size: 0.93rem;
}

.left-nav-link.grandchild-link:hover {
	color: #193885;
}

.left-nav-link.grandchild-link.w--current {
	padding-left: 0.35rem;
	box-shadow: -3px 0 0 0 #193885;
}

.left-nav-link.greatgrandchild-link {
	font-size: 0.85rem;
	line-height: 1.4rem;
}

.left-nav-link.greatgrandchild-link.w--current {
	box-shadow: -3px 0 0 0 #193885;
	color: #193885;
}

.left-nav-link.greatgrandchild-link.w--current:hover {
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.header-section {
	position: relative;
	z-index: 1000;
	width: 100%;
}

.header-section.header-section-search-top {
	position: fixed;
	top: 0px;
	width: 100%;
	top: 0px;
	z-index: 9999 !important;
	margin-bottom: -160px;
	padding-bottom: 0px;
	background-color: #fff;
	box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.3);
}

.navbar {
	position: -webkit-sticky;
	position: sticky;
	left: 0px;
	top: 60px;
	right: 0px;
	height: 108px;
	padding-right: 2rem;
	padding-left: 2rem;
	background-color: #fff;
}

.brand-2 {
	position: static;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	height: auto;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	cursor: pointer;
}

.brand-2.w--current {
	-webkit-transition-property: none;
	transition-property: none;
}

.nav-menu-2 {
	position: static;
	top: 95px;
	right: 0px;
	bottom: auto;
	z-index: 9;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	padding-right: 0%;
	padding-left: 0%;
	float: none;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	text-align: center;
}

.nav-menu-2.nav-menu-main {
	margin-right: 0px;
	margin-left: 0px;
}

.main-nav-link {
	position: relative;
	display: block;
	margin-right: 0rem;
	margin-left: 0rem;
	padding: 0.75rem;
	border-right: 1px solid transparent;
	border-left: 1px solid transparent;
	border-radius: 0px;
	box-shadow: 0 0 0 0 transparent;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	font-family: Lato, sans-serif;
	color: #193885;
	line-height: 1.4rem;
	font-weight: 400;
	text-decoration: none;
	text-transform: uppercase;
	cursor: pointer;
}

.main-nav-link:hover {
	box-shadow: inset 0 -6px 0 0 #193885;
	color: #193885;
}

.main-nav-link.w--current {
	box-shadow: inset 0 -6px 0 0 #612323;
	color: #612323;
}

.main-nav-link.mobile-duplicate {
	display: none;
}

.dropdown-wrapper {
	overflow: visible;
	position: relative;
	text-align: center;
	margin-bottom: 0px;
}

.dropdown-list {
	position: absolute;
	top: auto;
	z-index: auto;
	display: none;
	overflow: hidden;
	height: auto;
	max-width: 400px;
	min-width: 200px;
	padding: 0.5rem 0.3rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	background-color: #fff;
	opacity: 1;
	text-align: left;
}

.dropdown-link {
	display: block;
	margin-bottom: 2px;
	padding: 0.5rem 0.5rem 0.5rem 1rem;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	font-family: Lato, sans-serif;
	color: #312f2f;
	font-size: 1.1rem;
	font-weight: 300;
	text-decoration: none;
}

.dropdown-link:hover {
	background-color: #650030;
	color: #fff;
}

.dropdown-link.w--current {
	padding-right: 1rem;
	background-color: #612323;
	color: #fff;
}

.rotator-section {
	position: relative;
	z-index: 0;
	padding-top: 160px;
	background-color: #fff;
}

.rotator {
	height: 75vh;
	background-color: #ece7e3;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(236, 231, 227, 0.54)), to(rgba(236, 231, 227, 0.54))), url('/20181016082423/assets/images/blu-stripes_1blu-stripes.png');
	background-image: linear-gradient(180deg, rgba(236, 231, 227, 0.54), rgba(236, 231, 227, 0.54)), url('/20181016082423/assets/images/blu-stripes_1blu-stripes.png');
	background-position: 0px 0px, 0px 0px;
	background-size: auto, 50px;
}

.slideshow-arrow {
	left: auto;
	top: auto;
	right: 1rem;
	bottom: 1.5rem;
	display: none;
	width: 3rem;
	height: 3rem;
	background-color: transparent;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.slideshow-arrow:hover {
	background-color: hsla(0, 0%, 100%, 0.2);
	color: #312f2f;
}

.slideshow-arrow.left-arrow {
	display: none;
	margin-right: 3.5rem;
	background-color: transparent;
	color: #312f2f;
}

.slideshow-arrow.left-arrow:hover {
	background-color: hsla(0, 0%, 100%, 0.2);
	background-image: none;
	color: #fff;
}

.slidenav {
	padding-top: 0.75rem;
	font-size: 0.75rem;
}

.slide-caption-wrapper {
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 99;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 360px;
	margin-right: auto;
	margin-bottom: auto;
	margin-left: auto;
	padding: 1rem 2%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	background-color: transparent;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(25, 56, 133, 0.5)), to(rgba(25, 56, 133, 0.5))), -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0.5)), to(hsla(0, 0%, 100%, 0.5)));
	background-image: linear-gradient(180deg, rgba(25, 56, 133, 0.5), rgba(25, 56, 133, 0.5)), linear-gradient(180deg, hsla(0, 0%, 100%, 0.5), hsla(0, 0%, 100%, 0.5));
}

.slide-caption-header {
	width: 100%;
	margin-top: 0rem;
	font-family: Lato, sans-serif;
	color: #fff;
	font-size: 2rem;
	line-height: 2.8rem;
	font-weight: 400;
}

.slide-caption-paragraph {
	width: 100%;
	font-family: Lato, sans-serif;
	color: #fff;
	font-size: 1rem;
	line-height: 1.6em;
	font-weight: 300;
}

.arrow-icon {
	display: block;
	margin-right: auto;
	margin-left: auto;
	color: #fff;
	font-size: 1.5rem;
}

.arrow-icon:hover {
	color: #fff;
}

.slide {
	z-index: 0;
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
	cursor: pointer;
}

.slide.slide2 {
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, repeat;
}

.slide.slide3 {
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, repeat;
}

.home-content-section {
	display: none;
}

.slide-caption-wrapper .text-block {
	text-align: left;
}

.cb-static {
	width: 33.33%;
}

.cb-static.footer-cb-1 {
	width: 15%;
	padding-right: 0rem;
	padding-left: 0rem;
	background-color: transparent;
}

.cb-static.footer-cb-2 {
	width: 70%;
	padding-right: 0rem;
	padding-left: 0rem;
	background-color: transparent;
	text-align: center;
}

.cb-static.footer-cb-3 {
	width: 15%;
	padding-right: 0rem;
	padding-left: 0rem;
	background-color: transparent;
}

.cb-static.footer-cb-4 {
	width: 100%;
	text-align: center;
}

.footer-section {
	background-color: #612323;
	background-image: url('/20181016082423/assets/images/blu-stripes_1blu-stripes.png'), -webkit-gradient(linear, left top, left bottom, from(#612323), to(#612323));
	background-image: url('/20181016082423/assets/images/blu-stripes_1blu-stripes.png'), linear-gradient(180deg, #612323, #612323);
	background-position: 0px 0px, 0px 0px;
	background-size: 50px, auto;
}

.lower-content-wrapper {
	width: 65%;
	padding: 2.5rem 3rem 2.25rem;
	background-color: hsla(0, 0%, 100%, 0.9);
	text-align: center;
}

.copyright-paragraph {
	display: block;
	color: #fff;
	font-size: 0.8rem;
}

.signoff-link {
	display: inline-block;
	margin-right: 0.5rem;
	margin-bottom: 0.5rem;
	margin-left: 0.5rem;
	border-bottom: 1px solid #fff;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #fff;
	font-size: 0.9rem;
	text-decoration: none;
	cursor: pointer;
}

.signoff-link:hover {
	border-bottom-color: transparent;
	opacity: 0.65;
	color: #fff;
}

.lower-cb-paragraph {
	color: #312f2f;
	font-size: 1.125rem;
	line-height: 1.75em;
}

.social-media-link-block {
	width: 2.25rem;
	height: 2.25rem;
	margin-right: 0.5rem;
	margin-left: 0.5rem;
	background-image: url('/20181016082423/assets/images/facebook_wht_1facebook_wht.png');
	background-position: 50% 50%;
	background-size: 25px;
	background-repeat: no-repeat;
}

.social-media-link-block.footer-socmed-link-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 0rem;
	float: right;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: #c09f80;
	background-image: none;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	cursor: pointer;
}

.social-media-link-block.footer-socmed-link-block:hover {
	background-color: #ad8a69;
	background-image: none;
	opacity: 0.8;
}

.social-media-link-block.footer-socmed-link-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 2rem;
	height: 2rem;
	margin-top: 0rem;
	margin-right: 0.5rem;
	margin-left: 0rem;
	padding: 2px;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border: 2px solid transparent;
	border-radius: 0%;
	background-color: #193885;
	background-image: none;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	cursor: pointer;
}

.social-media-link-block.footer-socmed-link-block:hover {
	padding: 2px;
	border: 1px solid #fff;
	background-color: #193885;
	-webkit-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transform: scale(1.1);
}

.social-media-link-block.footer-socmed-link-block._1 {
	display: none;
}

.footer-links-wrapper {
	display: block;
	max-width: 700px;
	margin-right: auto;
	margin-bottom: 1.5rem;
	margin-left: auto;
	font-weight: 400;
}

.table {
	font-family: Lato, sans-serif;
}

.two-part-mobile-nav-wrapper {
	display: none;
}

.horiz-divider {
	height: 2px;
	margin-top: 0.5rem;
	margin-bottom: 1rem;
	background-color: #000;
}

.header-search-field {
	display: inline-block;
	width: 70%;
	height: 2.5rem;
	margin-bottom: 0px;
	float: left;
	border: 0px none transparent;
}

.header-search-field.search-top-field {
	width: 10%;
	height: 44px;
}

.search-dd-button {
	width: 3rem;
	margin-right: 2px;
	margin-left: 2px;
	padding: 0.75rem 1rem;
	background-image: url('/20181016082423/assets/images/search_blk.svg'), url('/20181016082423/assets/images/search_wht.svg');
	background-position: 50% 50%, 50% 50%;
	background-size: 17px 17px, 17px 17px;
	background-repeat: no-repeat, no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.search-dd-button.mobile-search-dd-button._1 {
	display: none;
}

.search-dd-button.search-top-button {
	display: none;
	padding-top: 1rem;
	padding-bottom: 2rem;
	border-right: 1px solid transparent;
	border-left: 1px solid transparent;
	background-image: url('/20181016082423/assets/images/search_wht.svg');
	background-position: 50% 50%;
	background-size: 17px 17px;
	background-repeat: no-repeat;
	box-shadow: inset 0 0 0 0 transparent;
	-webkit-transition-duration: 300ms;
	transition-duration: 300ms;
	cursor: pointer;
}

.search-dd-button.search-top-button:hover {
	background-image: url('/20181016082423/assets/images/search_wht.svg');
	background-position: 50% 50%;
	background-size: 17px 17px;
	background-repeat: no-repeat;
	box-shadow: inset 0 -6px 0 0 #fff;
}

.header-search-form {
	display: block;
	border-style: none;
}

.header-search-form-wrapper {
	width: 50%;
	margin-bottom: 0px;
	padding: 1rem;
	float: right;
	background-color: rgba(0, 0, 0, 0.5);
}

.header-search-form-wrapper.search-top-wrapper {
	display: block;
	width: 100%;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
	padding: 0.5rem 2rem 2rem;
	float: none;
	background-color: transparent;
}

.search-session-message {
	height: 0px;
	margin-top: 0px;
	padding: 0px;
	background-color: transparent;
	opacity: 0;
	color: transparent;
}

.breadcrumb-divider {
	display: inline-block;
	margin-right: 0.5rem;
	margin-left: 0.5rem;
	font-size: 1rem;
}

.success-message-box {
	border: 1px solid #1a5b0b;
	background-color: #f1fced;
	color: #1a5b0b;
}

.error-message-box {
	border: 1px solid #d50a2f;
	background-color: #fef3ee;
	color: #d50a2f;
}

.footer-heading {
	margin-top: 0rem;
	text-align: left;
}

.footer-heading.social-heading {
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-size: 1rem;
	text-align: right;
	text-transform: uppercase;
}

.cb-right {
	width: 60%;
	padding: 2.5rem 5% 2.25rem 3.5%;
}

.news-item {
	margin-bottom: 0.75rem;
	padding-bottom: 0.25rem;
	border-bottom: 1px dashed #c6ccbe;
}

.news-date {
	margin-top: 0.25rem;
	margin-bottom: 0.5rem;
	font-size: 0.8rem;
	font-weight: 400;
}

.news-abstract {
	margin-bottom: 0.75rem;
}

.intro-paragraph {
	margin-top: 0.5rem;
	margin-bottom: 1.5rem;
	font-family: Lato, sans-serif;
	color: #565656;
	font-size: 1.2rem;
	line-height: 2rem;
	font-weight: 400;
}

.header-flex-container {
	position: static;
	z-index: auto;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	height: 108px;
	margin: auto;
	padding: 0.25rem 0rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #fff;
	background-image: none;
	background-position: 0px 0px;
}

.header-search-container {
	position: absolute;
	left: 2px;
	top: 100%;
	right: 2px;
	display: block;
	overflow: hidden;
	width: 100%;
	height: 0px;
	max-width: 1200px;
	min-height: 0px;
	margin-top: -1px;
	margin-right: auto;
	margin-left: auto;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.header-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	height: 60px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	background-color: #193885;
	background-image: none;
	background-size: auto;
}

.close-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 0.75rem;
	padding: 0.25rem 0.25rem 0.25rem 0.5rem;
	float: right;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	opacity: 0.8;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	text-decoration: none;
}

.close-link:hover {
	opacity: 1;
}

.close-x {
	width: 1.5rem;
	height: 1.5rem;
	margin-left: 0rem;
	float: left;
	background-image: url('/20181016082423/assets/images/close_x_white.svg');
	background-position: 50% 67%;
	background-size: 13px 13px;
	background-repeat: no-repeat;
}

.close-text {
	padding-top: 3px;
	float: left;
	font-family: Lato, sans-serif;
	color: #fff;
	font-size: 0.9rem;
	line-height: 1em;
	font-weight: 500;
	text-decoration: none;
	text-transform: uppercase;
}

.search-container-push-down-rotator {
	overflow: hidden;
	height: 0vh;
}

.text-link {
	border-bottom: 1px solid rgba(25, 56, 133, 0.5);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #193885;
	font-weight: 500;
	text-align: left;
	text-decoration: none;
	cursor: pointer;
}

.text-link:hover {
	border-bottom-color: #fff;
	color: #650030;
}

.text-link.news-feed-link {
	border-bottom-color: rgba(68, 62, 63, 0.15);
	color: #612323;
	font-size: 1.1rem;
}

.text-link.news-feed-link:hover {
	border-bottom-color: #fff;
	color: #c09f80;
}

.text-link.alert-text-link {
	border-bottom-color: #000;
}

.text-link.alert-text-link:hover {
	border-bottom-color: #000;
	opacity: 0.65;
	color: #000;
}

.h2-heading {
	font-family: Merriweather, serif;
	color: #565656;
	font-weight: 400;
}

.list-item {
	margin-bottom: 0.25rem;
	font-family: Lato, sans-serif;
	color: #525252;
}

.h3-heading {
	font-family: Merriweather, serif;
	color: #565656;
	font-weight: 400;
}

.screen-message {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
}

.screen-message.success {
	background-color: #b5eebe;
}

.screen-message.error {
	background-color: #f5d1d1;
}

.screen-message-text {
	font-family: Lato, sans-serif;
	color: #000;
}

.para {
	line-height: 1.6rem;
}

.inside-page-section {
	padding-top: 168px;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(25, 56, 133, 0.05)), to(rgba(25, 56, 133, 0.05)));
	background-image: linear-gradient(180deg, rgba(25, 56, 133, 0.05), rgba(25, 56, 133, 0.05));
	background-position: 0px 0px;
	font-family: Lato, sans-serif;
}

.inside-page-banner-section {
	position: relative;
	display: block;
	height: 370px;
	padding-top: 220px;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	background-color: #ccc;
	background-position: 50% 38%;
	background-size: cover;
	background-repeat: no-repeat;
	background-image: url("/assets/images/default-banner.png");
}

.inside-content-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-bottom: 2rem;
}

.get-acrobat-link {
	width: 160px;
	height: 41px;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	cursor: pointer;
}

.get-acrobat-link:hover {
	opacity: 0.8;
}

.cb-header-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 1.5rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.cb-header-wrap.lower-cb-header-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 0.5rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.cb-header-icon {
	display: inline-block;
	width: 50px;
	height: 50px;
	margin-right: 1rem;
	border-radius: 50%;
	background-color: #07404b;
}

.cb-header-icon.cb-1-icon {
	background-color: #c09f80;
	background-image: url('/20181016082423/assets/images/laptop-1_white.svg');
	background-position: 50% 50%;
	background-size: 25px 25px;
	background-repeat: no-repeat;
}

.cb-header-icon.cb-2-icon {
	background-color: #c09f80;
	background-image: url('/20181016082423/assets/images/news_white.svg');
	background-position: 50% 50%;
	background-size: 25px 25px;
	background-repeat: no-repeat;
}

.cb-header-icon.lower-cb-icon {
	margin-right: 0rem;
	background-color: #c09f80;
	background-image: url('/20181016082423/assets/images/scales-of-justice2_white.svg');
	background-position: 50% 50%;
	background-size: 32px 32px;
	background-repeat: no-repeat;
}

.cb-list-link {
	display: block;
	padding-top: 0.75rem;
	padding-right: 2rem;
	padding-bottom: 0.75rem;
	border-bottom: 1px dashed rgba(137, 152, 158, 0.75);
	background-image: url('/20181016082423/assets/images/arrow2_right_black_50.svg');
	background-position: 100% 50%;
	background-size: 25px 25px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #312f2f;
	font-size: 1.1rem;
	font-weight: 500;
	text-decoration: none;
}

.cb-list-link:hover {
	background-image: url('/20181016082423/assets/images/arrow2_right_black.svg');
	background-size: 28px 28px;
	color: #612323;
}

.slide-image {
	display: none;
	width: 100%;
	height: 100%;
	background-color: #fff;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.slide-image.slide-image-2 {
	display: none;
	width: 100%;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: scroll;
}

.slide-image.slide-image-1 {
	display: none;
	width: 100%;
	background-image: none;
	background-position: 0px 0px;
	background-size: auto;
	background-repeat: repeat;
}

.mask {
	overflow: hidden;
}

.cb-heading {
	display: inline-block;
	margin-top: 0rem;
	margin-bottom: 0rem;
	font-family: Merriweather, serif;
	color: #565656;
	font-size: 2rem;
	line-height: 1.35em;
}

.cb-heading.court-finder-head {
	color: #fff;
	font-size: 1.75rem;
}

.cb-heading.lower-cb-heading {
	margin-top: 0.75rem;
	margin-bottom: 0.5rem;
	color: #565656;
	line-height: 1.6em;
}

.lower-content-flex-parent {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	padding-top: 6rem;
	padding-bottom: 6rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.photo-bg-section {
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1))), url('/20181016082423/assets/images/IMG_3401-footer.jpg');
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('/20181016082423/assets/images/IMG_3401-footer.jpg');
	background-position: 0px 0px, 50% 0%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
	background-attachment: scroll, fixed;
}

.court-search-text-field {
	display: block;
	width: 75%;
	height: 44px;
	margin-bottom: 0px;
	float: left;
	background-color: hsla(0, 0%, 100%, 0.9);
}

.court-finder-form-wrapper {
	display: block;
	width: 40%;
	margin-bottom: 0px;
	margin-left: 2rem;
}

.court-finder-section {
	padding-top: 2rem;
	padding-bottom: 2rem;
	border-top: 5px solid #cec1b7;
	background-color: #650030;
}

.court-finder-flex-parent {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 1rem;
	padding-left: 1rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	text-align: center;
}

.court-finder-search-form {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.cb-left {
	width: 40%;
	padding: 2.5rem 3.5% 2.5rem 5%;
	background-color: transparent;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(236, 231, 227, 0.54)), to(rgba(236, 231, 227, 0.54))), url('/20181016082423/assets/images/blu-stripes_1blu-stripes.png');
	background-image: linear-gradient(180deg, rgba(236, 231, 227, 0.54), rgba(236, 231, 227, 0.54)), url('/20181016082423/assets/images/blu-stripes_1blu-stripes.png');
	background-position: 0px 0px, 0px 0px;
	background-size: auto, 50px;
}

.header-link {
	border-bottom: 1px solid rgba(25, 56, 133, 0.5);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Lato, sans-serif;
	color: #193885;
	text-decoration: none;
}

.header-link:hover {
	border-bottom-color: transparent;
	color: #650030;
}

.header-link.h3-h6-link {
	font-family: Lato, sans-serif;
	color: #193885;
}

.header-link.h3-h6-link:hover {
	color: #650030;
}

.alert-section {
	display: none;
	padding: 1.5rem 2rem 1rem;
	border-top: 3px solid #c09f80;
	background-color: #dadada;
	text-align: center;
}

.alert-text {
	margin-top: 0rem;
	font-family: Lato, sans-serif;
	color: #000;
}

.footer-info-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.more-link {
	display: inline-block;
	padding-right: 24px;
	padding-left: 0rem;
	background-image: url('/20181016082423/assets/images/arrow2_right_white_50.svg');
	background-position: 100% 50%;
	background-size: 18px 18px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Lato, sans-serif;
	color: #fff;
	font-size: 1.2rem;
	font-weight: 400;
	text-decoration: none;
}

.more-link:hover {
	padding-right: 40px;
	background-image: url('/20181016082423/assets/images/arrow2_right_white_50.svg');
	color: #fff;
}

.more-link.link-blue {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-image: url('/20181016082423/assets/images/arrow2_right_red_50.svg');
	color: #650030;
}

.logo-image-name {
	display: inline-block;
}

.brand-sticky {
	position: relative;
	z-index: 2000;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 400px;
	margin-left: 0rem;
	opacity: 0;
}

.div-block {
	position: fixed;
	left: 0px;
	top: 70px;
	right: 12rem;
	bottom: 1.75rem;
	z-index: auto;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 69.796875px;
	margin-right: 0rem;
	padding-right: 0rem;
	padding-bottom: 0rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	border-top: 1px none rgba(0, 0, 0, 0.1);
	background-color: transparent;
}

.alert-text-link {
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #000;
	font-weight: 500;
}

.alert-text-link:hover {
	color: #662c2c;
	text-decoration: none;
}

.page-title {
	margin-top: 0.6rem;
	margin-bottom: 0.6rem;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	font-family: Lato, sans-serif;
	color: #fff;
	font-size: 2.4rem;
	font-weight: 400;
	text-align: left;
	text-shadow: none;
}

.page-title.no-sidebars-page-title {
	margin-right: 15px;
	margin-left: 15px;
	line-height: 1.5em;
}

.page-title-container {
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: 0px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
	padding-right: 2%;
	padding-left: 2%;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	border-top: 5px none #193885;
	background-color: #193885;
	font-family: Lato, sans-serif;
	font-size: 2.4rem;
	line-height: 1.5em;
}

.page-title-left-spacer {
	display: none;
	width: 20%;
	margin-right: 3%;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}

.unordered-list-2 {
	margin-bottom: 0rem;
}

.unordered-list-3 {
	margin-bottom: 0rem;
}

.heading-16 {
	color: #565656;
}

.fake-sv-image-example {
	width: 100%;
	height: 400px;
	background-image: url('/20181016082423/assets/images/44cddb3a-4aa5-4862-85bc-6b59f9a58798-CourtHouse2.webp');
	background-position: 50% 100%;
	background-size: cover;
	background-repeat: no-repeat;
}

.breadcrumbs-list {
	display: inline-block;
	margin-bottom: 0px;
	padding-left: 0px;
}

.breadcrumbs-list-item {
	display: inline-block;
	float: left;
}

.logo-full {
	height: 100px;
	-webkit-transition: opacity 200ms ease;
	transition: opacity 200ms ease;
}

.footer-grip-link-2 {
	width: 133px;
	height: 29px;
	min-width: 0px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding-left: 33px;
	background-image: url('/20181016082423/assets/images/globalreach_dark.svg');
	background-position: 50% 50%;
	background-size: 130px 29px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	text-decoration: none;
	cursor: pointer;
}

.footer-grip-link-2.white {
	width: 150px;
	margin-top: 0rem;
	margin-bottom: 0px;
	background-image: url('/20181016082423/assets/images/globalreach_white.svg');
	background-size: 150px 29px;
}

.footer-grip-link-2.white {
	background-image: url('/20181016082423/assets/images/globalreach_white.svg');
	background-size: 150px 29px;
}

.grip-logo-text-box-2 {
	color: #000;
	font-size: 0.5rem;
	line-height: 0.5rem;
	font-weight: 700;
	letter-spacing: 0.01rem;
	text-decoration: none;
	text-transform: uppercase;
}

.grip-logo-text-box-2.webdevby-white {
	color: #fff;
}

.main-nav-link-2 {
	position: relative;
	z-index: 1000;
	display: block;
	margin-right: 0rem;
	margin-left: 0rem;
	padding: 0.75rem;
	border-right: 1px solid transparent;
	border-left: 1px solid transparent;
	box-shadow: inset 0 0 0 0 transparent;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	font-family: Lato, sans-serif;
	color: #193885;
	line-height: 1.4rem;
	font-weight: 400;
	text-decoration: none;
	text-transform: uppercase;
	cursor: pointer;
}

.main-nav-link-2:hover {
	box-shadow: inset 0 -6px 0 0 #193885;
	color: #193885;
}

.main-nav-link-2.w--current {
	box-shadow: inset 0 -6px 0 0 #662c2c;
	color: #00788d;
}

.heading-17 {
	font-family: Lato, sans-serif;
	color: #525252;
}

.block-quote {
	border-left-color: #193885;
	background-color: rgba(25, 56, 133, 0.15);
	font-family: Lato, sans-serif;
}

.heading-18 {
	font-family: Lato, sans-serif;
	color: #565656;
}

.heading-19 {
	color: #565656;
}

.heading-20 {
	font-family: Lato, sans-serif;
	color: #565656;
}

.heading-21 {
	font-family: Lato, sans-serif;
	color: #565656;
}

.heading-22 {
	font-family: Lato, sans-serif;
	color: #565656;
}

.heading-23 {
	font-family: Lato, sans-serif;
	color: #565656;
}

.heading-24 {
	font-family: Lato, sans-serif;
	color: #565656;
}

.heading-25 {
	font-family: Lato, sans-serif;
	color: #565656;
}

.heading-26 {
	font-family: Lato, sans-serif;
	color: #565656;
}

.heading-27 {
	font-family: Lato, sans-serif;
	color: #565656;
}

.heading-28 {
	font-family: Lato, sans-serif;
	color: #565656;
}

.heading-29 {
	font-family: Lato, sans-serif;
	color: #565656;
}

.heading-30 {
	font-family: Lato, sans-serif;
	color: #565656;
}

.heading-31 {
	font-family: Lato, sans-serif;
	color: #565656;
}

.heading-32 {
	font-family: Lato, sans-serif;
	color: #565656;
}

.heading-33 {
	font-family: Lato, sans-serif;
	color: #565656;
}

.heading-34 {
	font-family: Lato, sans-serif;
	color: #565656;
}

.heading-35 {
	font-family: Lato, sans-serif;
	color: #565656;
}

.heading-36 {
	font-family: Lato, sans-serif;
	color: #565656;
}

.heading-37 {
	font-family: Lato, sans-serif;
	color: #565656;
}

.heading-38 {
	font-family: Lato, sans-serif;
	color: #565656;
}

.heading-39 {
	font-family: Lato, sans-serif;
	color: #565656;
}

.heading-40 {
	font-family: Lato, sans-serif;
	color: #565656;
}

.heading-41 {
	font-family: Lato, sans-serif;
	color: #565656;
}

.heading-42 {
	font-family: Lato, sans-serif;
	color: #565656;
}

.heading-43 {
	font-family: Lato, sans-serif;
	color: #565656;
}

.heading-44 {
	font-family: Lato, sans-serif;
	color: #565656;
}

.heading-45 {
	font-family: Lato, sans-serif;
	color: #565656;
}

.heading-46 {
	font-family: Lato, sans-serif;
	color: #565656;
}

.heading-47 {
	font-family: Lato, sans-serif;
	color: #565656;
}

.heading-48 {
	font-family: Lato, sans-serif;
	color: #565656;
}

.heading-49 {
	font-family: Lato, sans-serif;
	color: #565656;
}

.heading-50 {
	color: #565656;
}

.heading-51 {
	font-family: Lato, sans-serif;
	color: #565656;
}

.heading-52 {
	font-family: Lato, sans-serif;
	color: #565656;
}

.heading-53 {
	font-family: Lato, sans-serif;
	color: #565656;
}

.heading-54 {
	font-family: Lato, sans-serif;
	color: #565656;
}

.heading-55 {
	font-family: Lato, sans-serif;
	color: #565656;
}

.heading-56 {
	font-family: Lato, sans-serif;
	color: #565656;
}

.heading-57 {
	color: #565656;
}

.heading-58 {
	color: #565656;
}

.heading-59 {
	color: #565656;
}

.heading-60 {
	color: #565656;
}

.heading-61 {
	color: #565656;
}

.heading-62 {
	color: #565656;
}

.heading-63 {
	color: #565656;
}

.heading-64 {
	color: #565656;
}

.button-login {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100.0002098%;
	height: 2rem;
	margin-right: 2rem;
	padding: 10px 20px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #193885;
	text-decoration: none;
}

.button-login:hover {
	background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0.1)), to(hsla(0, 0%, 100%, 0.1)));
	background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.1), hsla(0, 0%, 100%, 0.1));
	font-family: Lato, sans-serif;
}

.heading-65 {
	margin-top: 0rem;
	margin-bottom: 0rem;
	font-family: Lato, sans-serif;
	color: #fff;
	font-size: 0.95rem;
	line-height: 1rem;
	font-weight: 400;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
}

.div-block-3 {
	display: none;
	background-color: transparent;
	background-image: url('/20181016082423/assets/images/user-3.png');
	background-position: 0px 0px;
	background-size: auto;
}

.link {
	display: none;
	font-family: Lato, sans-serif;
	color: #fff;
	font-size: 1.2rem;
	font-weight: 300;
	text-decoration: none;
}

.link:hover {
	opacity: 0.75;
}

.button-login-wrapper {
	position: absolute;
	top: 2.5rem;
	right: 0px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	width: auto;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.div-block-4 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 260px;
	margin-right: 0rem;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.link-block {
	width: 50px;
	height: 50px;
	margin-right: 20px;
	background-image: url('/20181016082423/assets/images/White-Twitter.svg');
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
	opacity: 0.65;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.link-block:hover {
	opacity: 1;
}

.div-block-5 {
	width: 11%;
}

.social-media-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.social-media-wrapper.footer-socmed-wrapper {
	float: right;
}

.social-media-wrapper.footer-socmed-wrapper.social-2 {
	margin-right: 2rem;
	float: none;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.social-media-wrapper.footer-socmed-wrapper.social-2.footer-icons {
	margin-top: 1.5rem;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.footer-social-icon-image-2 {
	display: block;
	width: 29px;
	margin-right: auto;
	margin-left: auto;
}

.footer-social-icon-image-2.issuu {
	width: 18px;
	height: 18px;
}

.div-block-6 {
	position: absolute;
	left: auto;
	top: auto;
	right: 180px;
	bottom: 0%;
	z-index: auto;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 45%;
	padding-top: 0.25rem;
	padding-bottom: 1.75rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
}

.div-block-7 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	color: #fff;
}

.location-icon {
	width: 1.1rem;
}

.footer-nav-item {
	margin-bottom: 0px;
	margin-top: 0rem;
	padding-top: 0.75rem;
	color: #fff;
	font-size: 0.7rem;
	line-height: 0.9rem;
	letter-spacing: 0.1em;
	text-transform: uppercase;
}

.certifications-icons {
	display: none;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-align-content: stretch;
	-ms-flex-line-pack: stretch;
	align-content: stretch;
}

.footer-left-content-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-bottom: 1rem;
	padding-left: 0rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.phone-icon {
	width: 1rem;
}

.footer-box {
	position: static;
	left: auto;
	top: auto;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	width: auto;
	height: auto;
	max-width: none;
	margin-bottom: 0rem;
	margin-left: 0px;
	padding: 4rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: transparent;
}

.footer-box.footer-section {
	display: block;
	padding: 2rem 0rem 0rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-top: 1px solid #dadada;
	background-color: #fff;
	background-image: none;
	background-size: auto;
}

.footer-box.quick-links-section {
	position: relative;
	margin-right: auto;
	margin-left: 0px;
	padding-top: 3rem;
	padding-bottom: 3rem;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	background-color: #fff;
	background-image: none;
	background-size: auto;
}

.footer-box.quick-links-section {
	position: relative;
	padding-top: 3rem;
	padding-bottom: 3rem;
	background-color: #fff;
	background-size: auto;
}

.footer-grip-link {
	width: 133px;
	height: 29px;
	min-width: 0px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding-left: 33px;
	background-image: url('/20181016082423/assets/images/globalreach_dark.svg');
	background-position: 50% 50%;
	background-size: 130px 29px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	text-decoration: none;
	cursor: pointer;
}

.footer-grip-link.white-logo {
	width: 14px;
	height: 30px;
	margin-top: 0px;
	margin-right: 0rem;
	margin-bottom: 0rem;
	background-image: url('/20181016082423/assets/images/globalreach_white.svg');
	background-size: 150px 29px;
}

.footer-list-item-link {
	padding-left: 0rem;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
	opacity: 0.75;
	-webkit-transition: color 300ms ease;
	transition: color 300ms ease;
	font-family: Lato, sans-serif;
	color: #193885;
	font-size: 1rem;
	line-height: 1rem;
	font-weight: 400;
	letter-spacing: 0em;
	text-decoration: none;
	text-transform: capitalize;
	border-bottom: 1px solid transparent;
}

.footer-list-item-link:hover {
	border-bottom: 1px none #888;
	background-image: none;
	opacity: 1;
	color: #193885;
}

.footer-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: none;
	margin-right: 0px;
	margin-left: 0px;
	padding-bottom: 2rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.footer-wrapper.signoff-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: none;
	margin-top: 0rem;
	padding: 2rem 12vw 1.5rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom-style: none;
	background-color: #454449;
}

.footer-wrapper.signoff-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: none;
	margin-top: 0rem;
	padding: 2rem 12vw 1.5rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom-style: none;
	background-color: #193885;
}

.footer-link-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	opacity: 1;
}

.footer-link-wrapper.first {
	width: auto;
	margin-bottom: 0.85rem;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	opacity: 1;
	color: #193885;
}

.cb-static-2 {
	width: 33.33%;
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.cb-static-2.footer-logo-left {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	margin-right: 0rem;
	padding-right: 0rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.cb-static-2.footer-cb-left {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	margin-right: 0rem;
	padding-top: 0rem;
	padding-right: 0rem;
	padding-bottom: 0rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.cb-static-2.footer-cb-left.top-cb-static {
	opacity: 1;
	font-family: Lato, sans-serif;
}

.signoff-left-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 60%;
	margin-left: 0rem;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	color: #bdbdbd;
	font-size: 0.8rem;
	line-height: 1.2rem;
}

.footer-signoff-link {
	width: 20%;
	margin-right: 0.25rem;
	margin-left: 0.25rem;
	border-bottom: 1px solid transparent;
	opacity: 0.75;
	-webkit-transition: all 250ms ease;
	transition: all 250ms ease;
	color: #fff;
	font-size: 0.75rem;
	line-height: 0.95rem;
	font-weight: 400;
	text-decoration: none;
}

.footer-signoff-link:hover {
	border-bottom: 1px none #fff;
	opacity: 1;
	color: #fff;
}

.footer-signoff-link.first-signoff-link {
	width: 20%;
	margin-right: 0.25rem;
	margin-left: 0rem;
	font-family: Lato, sans-serif;
}

.footer-signoff-link._1 {
	display: none;
}

.footer-signoff-link.last-signoff-link {
	width: 40%;
}

.equal-housing-lender-img {
	height: 25px;
	margin-right: 2rem;
	margin-bottom: 0.5rem;
	margin-left: 0px;
}

.cb-heading-2 {
	display: inline-block;
	margin-top: 0rem;
	margin-bottom: 1rem;
	color: #fff;
	font-size: 2.286rem;
	line-height: 2.986rem;
	font-weight: 400;
}

.cb-heading-2.footer-heading {
	display: block;
	margin-bottom: 0.25rem;
	border-bottom: 2px none rgba(255, 255, 255, 0.2);
	font-family: Lato, sans-serif;
	color: #193885;
	font-size: 1.1rem;
	line-height: 2.5rem;
	font-weight: 700;
	letter-spacing: 0rem;
	text-transform: none;
}

.footer-middle-wrapper {
	width: 35%;
}

.copyright-paragraph-2 {
	margin-top: 0.5rem;
	display: inline-block;
	margin-right: 1rem;
	margin-bottom: 0px;
	opacity: 0.75;
	font-family: Lato, sans-serif;
	color: #fff;
	font-size: 0.75rem;
	font-weight: 400;
}

.copyright-paragraph-2 h1 {
	display: inline;
	margin: 0px;
	padding: 0px;
	font-size: inherit;
	line-height: inherit;
	color: inherit;
	font-family: inherit;
	font-weight: inherit;
	letter-spacing: inherit;
}

.footer-icon-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 1.65rem;
	height: 1.65rem;
	margin-right: 0.5rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 0px;
	background-color: #193885;
}

.footer-right-content-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding-right: 0rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.footer-text {
	padding-top: 0.35rem;
	opacity: 1;
	font-family: Lato, sans-serif;
	color: #193885;
	font-size: 1rem;
	line-height: 1rem;
	font-weight: 400;
}

.link-2 {
	overflow: visible;
	opacity: 1;
	-webkit-transition: opacity 200ms ease;
	transition: opacity 200ms ease;
	font-family: Lato, sans-serif;
	color: #193885;
	font-size: 1rem;
	line-height: 0.9rem;
	font-weight: 400;
	text-decoration: none;
}

.link-2:hover {
	opacity: 1;
}

.footer-content-section {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: none;
	margin-right: 0px;
	margin-left: 0px;
	padding: 0rem 12vw;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.footer-links-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-top: 0.6rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	opacity: 1;
	color: #193885;
}

.logo {
	width: 165px;
	max-width: 200px;
	min-width: auto;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
}

.footer-vertical-divider {
	width: 1px;
	height: 10px;
	margin-right: 0.25rem;
	margin-left: 0.25rem;
	background-color: #fff;
}

.footer-vertical-divider._1 {
	display: none;
}

.member-fdic-img {
	height: 25px;
	margin-right: 0rem;
	margin-left: 0px;
}

.footer-divider-line {
	width: 35%;
	height: 1px;
	background-color: rgba(25, 56, 133, 0.8);
}

.signoff-link-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-left: 0rem;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.footer-nav-list {
	margin-top: 0rem;
	font-size: 0.9rem;
	line-height: 1.65rem;
}

.link-3 {
	font-family: Lato, sans-serif;
	color: #fff;
	font-size: 3rem;
	line-height: 3em;
	text-decoration: none;
}

.div-block-69 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 300px;
	height: 80px;
	margin-bottom: 10px;
	padding: 20px;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border: 1px solid #fff;
	-webkit-transition: border-color 300ms ease, background-color 400ms ease;
	transition: border-color 300ms ease, background-color 400ms ease;
}

.div-block-69:hover {
	border-color: #650030;
	background-color: #650030;
}

.text-block {
	margin-right: 0rem;
	font-family: Lato, sans-serif;
	color: #fff;
	font-size: 1.6rem;
	line-height: 1em;
}

.image {
	display: none;
	line-height: 1rem;
}

.text-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 500px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	font-family: Lato, sans-serif;
	line-height: 1rem;
}

.icon-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	width: 6rem;
	height: 6rem;
	margin-bottom: 0rem;
	padding-right: 20px;
	padding-left: 20px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 50px;
	background-color: #193885;
}

.icon-img {
	width: 4rem;
	opacity: 1;
}

.h3-headings {
	display: inline-block;
	max-width: 500px;
	margin-top: 1rem;
	margin-right: 0rem;
	margin-bottom: 0rem;
	padding-right: 0rem;
	border-right: 5px none #3eedb6;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
	font-family: 'Open Sans', sans-serif;
	color: #1c3d66;
	font-size: 1.1rem;
	line-height: 1rem;
	font-weight: 700;
	text-align: center;
	letter-spacing: 0px;
	text-indent: 0px;
	text-transform: none;
}

.h3-headings.icon-headings {
	margin-bottom: 1.25rem;
	font-family: Lato, sans-serif;
	font-size: 1.3rem;
	letter-spacing: 0rem;
}

.icon-link-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 25%;
	height: auto;
	padding: 0rem 2rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border: 1px solid transparent;
	background-color: transparent;
	text-decoration: none;
}

.paragraph {
	margin-bottom: 0px;
	padding-top: 0rem;
	padding-bottom: 0rem;
	font-family: 'Open Sans', sans-serif;
	color: #000;
	font-size: 0.95rem;
	text-align: center;
	text-decoration: none;
}

.icon-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 40px;
	max-width: none;
	margin-top: 0rem;
	margin-bottom: 0rem;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.quick-links-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.cb-card-desc {
	font-family: Montserrat, sans-serif;
	font-size: 0.95em;
}

.cb-card-content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 0px;
	margin-bottom: 10px;
	margin-left: 0px;
	padding-top: 20px;
	padding-bottom: 20px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-transition: all 300ms ease, background-color 300ms ease;
	transition: all 300ms ease, background-color 300ms ease;
	text-decoration: none;
}

.cb-card-content:hover {
	background-color: #650030;
	-webkit-transform: scale(1.03);
	-ms-transform: scale(1.03);
	transform: scale(1.03);
}

.link-4 {
	color: #008266;
	font-weight: 700;
}

.link-4:hover {
	color: #c2d600;
}

.cb-card-title-link {
	font-family: Montserrat, sans-serif;
	color: #193885;
	font-weight: 700;
	text-decoration: none;
}

.cb-card-title-link:hover {
	color: #c2d600;
}

.cb-card-img {
	width: 100%;
	background-image: -webkit-gradient(linear, left top, left bottom, from(#193885), to(#193885));
	background-image: linear-gradient(180deg, #193885, #193885);
}

.content-box-section {
	display: block;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	border-bottom: 10px none #c2d600;
	background-color: #fff;
}

.content-boxes-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 1250px;
	margin-right: auto;
	margin-left: auto;
	padding: 30px 20px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.cb-card-img-wrap {
	display: none;
	margin-bottom: 20px;
}

.cb-card {
	position: relative;
	width: 32%;
	border-top: 10px none #c2d600;
	background-color: transparent;
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	display: flex;
	flex-direction: column;
}

.cb-card-title {
	margin: 10px 20px;
	font-family: Lato, sans-serif;
	color: #193885;
	font-size: 2rem;
	line-height: 2rem;
}

.text-block-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 10px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	font-family: Lato, sans-serif;
	color: #fff;
	font-size: 1.4rem;
	font-weight: 700;
	text-align: center;
	text-decoration: none;
}

.card-wrapper {
	height: 200px;
	padding-top: 20px;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(25, 56, 133, 0.5)), to(rgba(25, 56, 133, 0.5))), url('/20181016082423/assets/images/scott-graham-OQMZwNd3ThU-unsplash-1.jpg');
	background-image: linear-gradient(180deg, rgba(25, 56, 133, 0.5), rgba(25, 56, 133, 0.5)), url('/20181016082423/assets/images/scott-graham-OQMZwNd3ThU-unsplash-1.jpg');
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
}

.card-wrapper.card2 {
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(101, 0, 48, 0.5)), to(rgba(101, 0, 48, 0.5))), url('/20181016082423/assets/images/scott-graham-OQMZwNd3ThU-unsplash-1.jpg');
	background-image: linear-gradient(180deg, rgba(101, 0, 48, 0.5), rgba(101, 0, 48, 0.5)), url('/20181016082423/assets/images/scott-graham-OQMZwNd3ThU-unsplash-1.jpg');
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
}

.heading-66 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	font-family: Lato, sans-serif;
	color: #fff;
}

.heading-67 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0rem;
	padding-top: 0rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	font-family: Lato, sans-serif;
	color: #fff;
	line-height: 1.2em;
	text-align: center;
}

.div-block-71 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 20px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.image-2 {
	width: 4rem;
}

.text-block-3 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding-right: 0;
	justify-content: center;
	font-family: Lato, sans-serif;
	color: #fff;
	font-size: 0.9rem;
	line-height: 1.2em;
}

.div-block-72 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.text-block-4 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding-left: 10px;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	font-family: Lato, sans-serif;
	color: #fff;
	font-size: 0.9rem;
	line-height: 1.2em;
}

.description-wrapper {
	padding: 20px 20px 40px;
	background-color: rgba(25, 56, 133, 0.8);
	flex-grow: 1;
	color: #fff;
}

.description-wrapper.description-wrapper2 {
	background-color: rgba(101, 0, 48, 0.8);
}

.div-block-74 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.divider {
	height: 0.5px;
	margin-bottom: 10px;
	padding-right: 20px;
	padding-left: 20px;
	border: 0.75px solid #fff;
	background-color: #fff;
	color: #fff;
}

.button-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 20px;
	margin-left: 6rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.button-wrapper.wrapper1 {
	margin-left: 0rem;
}

.button-wrapper.wrapper1._1 {
	display: none;
}

.flexbar-images {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	width: auto;
	height: 450px;
}

.flexbar-image {
	position: absolute;
	width: 100vw;
	height: 80vh;
	background-image: url('/20181016082423/assets/images/gerold-hinzen-WoZs8gGyQBY-unsplash.jpg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.flexbar-image._1 {
	z-index: -10;
	display: block;
	overflow: visible;
	height: 450px;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.6)), to(rgba(0, 0, 0, 0.6))), url('/20181016082423/assets/images/giammarco-zeH-ljawHtg-unsplash.jpg');
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('/20181016082423/assets/images/giammarco-zeH-ljawHtg-unsplash.jpg');
	background-position: 0px 0px, 0% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.flexbar-image._2 {
	z-index: -10;
	display: block;
	overflow: visible;
	height: 450px;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.6)), to(rgba(0, 0, 0, 0.6))), url('/20181016082423/assets/images/e51e0eea-8e20-45c7-81f4-94fe9465c189-CourtHouse8.webp');
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('/20181016082423/assets/images/e51e0eea-8e20-45c7-81f4-94fe9465c189-CourtHouse8.webp');
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.flexbar-paragraph {
	max-width: 550px;
	margin-bottom: 15px;
	margin-left: 6rem;
	padding-right: 0px;
	padding-left: 0px;
	color: #fff;
	font-size: 0.95rem;
	line-height: 1.5rem;
	font-weight: 400;
	text-align: left;
	text-decoration: none;
}

.flexbar-paragraph._1 {
	max-width: 500px;
	margin-right: 0rem;
	margin-bottom: 2.5rem;
	font-family: Lato, sans-serif;
	font-size: 1.125rem;
	line-height: 1.75rem;
	font-weight: 300;
}

.flexbar-paragraph._2 {
	max-width: 500px;
	margin-right: 0rem;
	margin-bottom: 2.5rem;
	margin-left: 6rem;
	font-family: Lato, sans-serif;
	font-size: 1.125rem;
	line-height: 1.75rem;
	font-weight: 300;
}

.link-horiz-line-2 {
	width: 15px;
	height: 2px;
	margin-right: 0.5rem;
	background-color: #b08d57;
}

.link-horiz-line-2.white-line {
	background-color: #fff;
}

.projects-section-mobile {
	display: none;
}

.flexbar-col-image {
	position: relative;
	z-index: 1000;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 25%;
	height: 80vh;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-right: 1.5px solid rgba(112, 128, 145, 0.25);
	background-color: #222;
	cursor: pointer;
}

.flexbar-col-image:hover {
	background-color: #222;
}

.flexbar-col-image._1 {
	position: absolute;
	z-index: -1;
	display: block;
	width: 100%;
	height: 600px;
	border-right-style: none;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.4)), to(rgba(0, 0, 0, 0.4))), url('/20181016082423/assets/images/giammarco-zeH-ljawHtg-unsplash.jpg');
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('/20181016082423/assets/images/giammarco-zeH-ljawHtg-unsplash.jpg');
	background-position: 0px 0px, 75% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.flexbar-col-image._1:hover {
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.4)), to(rgba(0, 0, 0, 0.4)));
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
	background-position: 0px 0px;
	background-size: auto;
	background-repeat: repeat;
}

.flexbar-col-image._2 {
	position: absolute;
	z-index: -1;
	display: block;
	width: 100%;
	height: 600px;
	border-right-style: none;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.4)), to(rgba(0, 0, 0, 0.4))), url('/20181016082423/assets/images/e51e0eea-8e20-45c7-81f4-94fe9465c189-CourtHouse8.webp');
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('/20181016082423/assets/images/e51e0eea-8e20-45c7-81f4-94fe9465c189-CourtHouse8.webp');
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.secondary-button1 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 0rem;
	margin-left: 0rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	text-decoration: none;
}

.secondary-button1.flexbar-link-two {
	margin-left: 6rem;
}

.secondary-button1.flexbar-link {
	margin-left: 6rem;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.link-text-2 {
	color: #444545;
	font-size: 0.9rem;
	line-height: 1rem;
	font-weight: 400;
	letter-spacing: 1px;
	text-decoration: none;
	text-transform: uppercase;
}

.link-text-2.white-text-link {
	color: #fff;
	font-weight: 300;
}

.flexbar-725-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: 725px;
	min-width: auto;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.flexbar-section-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	width: 100vw;
	height: auto;
	background-color: transparent;
}

.flexbar-heading {
	margin-top: 0px;
	margin-bottom: 20px;
	background-color: transparent;
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-size: 2.75rem;
	line-height: 2.75rem;
	text-align: left;
	letter-spacing: 2px;
	text-transform: none;
}

.flexbar-heading.col {
	margin-bottom: 10px;
	color: #fff;
	font-size: 1.25rem;
	line-height: 2.5rem;
	font-weight: 500;
	text-align: left;
	letter-spacing: 0px;
	text-decoration: none;
}

.flexbar-heading.col._1 {
	margin-right: 6rem;
	margin-bottom: 1rem;
	margin-left: 6rem;
	padding-right: 1rem;
	padding-left: 0rem;
	font-family: Lato, sans-serif;
	font-size: 2.5rem;
	line-height: 2.5rem;
	font-weight: 400;
	text-align: left;
	text-transform: capitalize;
}

.flexbar-heading.col._2 {
	margin-right: 6rem;
	margin-bottom: 1rem;
	margin-left: 6rem;
	padding-right: 1rem;
	padding-left: 0rem;
	font-family: Lato, sans-serif;
	font-size: 2.5rem;
	line-height: 2.5rem;
	font-weight: 400;
	text-align: left;
	text-transform: capitalize;
}

.flexbar-column-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100vw;
	height: 450px;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
}

.flexbar-col {
	position: absolute;
	left: 50%;
	z-index: -1;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 25%;
	height: 80vh;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	text-decoration: none;
}

.flexbar-col._1 {
	left: 0%;
	z-index: auto;
	overflow: hidden;
	width: 50%;
	height: 450px;
	padding-left: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	border-right: 1px solid rgba(255, 255, 255, 0.4);
	-webkit-transition: all 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition: all 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	cursor: pointer;
}

.flexbar-col._2 {
	left: 50%;
	z-index: auto;
	overflow: hidden;
	width: 50%;
	height: 450px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	border-right: 1px none #969797;
}

.number-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	margin-bottom: 0.5rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.stat-number {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	height: auto;
	margin-right: 0rem;
	margin-bottom: 0rem;
	margin-left: 0rem;
	padding: 0rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 0px;
	background-color: transparent;
	color: #c51b00;
	font-size: 4.5rem;
	line-height: 4rem;
	font-weight: 300;
}

.image-3 {
	width: 40px;
}

.stat-content-wrapper {
	position: relative;
	z-index: 10;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.number-wrapper-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	margin-bottom: 0rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.stat-title-2 {
	width: 50%;
	max-width: none;
	margin-bottom: 0rem;
	color: #335962;
	font-size: 1.125rem;
	line-height: 1.15rem;
	font-weight: 400;
	text-align: left;
	letter-spacing: 0px;
	text-transform: none;
}

.stats-cb {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	height: auto;
	margin-right: 0rem;
	margin-bottom: 0rem;
	padding: 1.25rem 0rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	border-style: none;
	border-width: 1px;
	border-color: #e2e2e2 #000;
	border-radius: 0px;
	background-color: transparent;
	-webkit-transition: box-shadow 500ms ease;
	transition: box-shadow 500ms ease;
	text-decoration: none;
}

.stats-cb:hover {
	border: 1px none #000;
	background-color: transparent;
}

.stats-cb.two {
	margin-right: 5vw;
	margin-left: 5vw;
	padding: 1.25rem 0rem;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	border: 1px none #000;
}

.stats-cb.two:hover {
	border: 1px none #000;
}

.stats-cb.four {
	margin-right: 0rem;
	padding: 0rem;
	border-style: none;
	border-color: #000;
}

.stats-cb.four:hover {
	border-style: none;
	border-width: 1px;
}

.stat-title {
	max-width: none;
	margin-bottom: 0rem;
	color: #335962;
	font-size: 1rem;
	line-height: 1.15rem;
	font-weight: 400;
	text-align: left;
	letter-spacing: 0px;
	text-transform: uppercase;
}

.image-4 {
	width: 50px;
}

.paragraph-2 {
	max-width: 500px;
	margin-bottom: 2.5rem;
	padding-top: 0rem;
	padding-bottom: 0rem;
	color: #444545;
	font-size: 1.125rem;
	line-height: 1.75rem;
	font-weight: 300;
	text-align: left;
	text-decoration: none;
}

.paragraph-2.first-paragraph {
	max-width: 500px;
	margin-bottom: 1rem;
	text-align: left;
}

.stat-number-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	height: auto;
	margin-right: 0.5rem;
	margin-bottom: 0rem;
	margin-left: 0rem;
	padding: 0.5rem 0rem 0.5rem 1rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 0px;
	background-color: transparent;
	font-family: Montserrat, sans-serif;
	color: #c41100;
	font-size: 3.25rem;
	line-height: 3.25rem;
	font-weight: 400;
}

.tertiary-button {
	position: relative;
	right: auto;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 1rem;
	margin-left: 0rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	text-decoration: none;
}

.tertiary-button.about-section {
	position: static;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0rem;
	margin-bottom: 0rem;
	padding-bottom: 0px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-style: none;
	border-width: 1px;
	border-color: #b08d57;
}

.section-content {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: none;
	padding: 2rem 6rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: transparent;
	text-align: center;
}

.text-link-2 {
	color: #b08d57;
	font-size: 0.9rem;
	line-height: 1rem;
	font-weight: 400;
	letter-spacing: 1px;
	text-decoration: none;
	text-transform: uppercase;
}

.stat-icon-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50px;
	height: 50px;
	margin-right: 0.25rem;
	padding: 0px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 100px;
	background-color: transparent;
}

.stat-icon-2.years {
	padding-right: 3px;
	padding-left: 3px;
}

.stat-icon-2.offices {
	margin-right: 0.25rem;
	padding-right: 3px;
	padding-left: 3px;
}

.stat-text-wrapper-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.stat-content-wrapper-2 {
	position: relative;
	z-index: 10;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.stats-section-2 {
	display: -ms-grid;
	display: grid;
	width: 40%;
	padding-right: 0rem;
	padding-left: 0rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: stretch;
	-webkit-justify-content: stretch;
	-ms-flex-pack: stretch;
	justify-content: stretch;
	justify-items: stretch;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	grid-auto-flow: column;
	grid-auto-columns: 1fr;
	grid-column-gap: 0px;
	grid-row-gap: 16px;
	-ms-grid-columns: 1fr 1fr;
	grid-template-columns: 1fr 1fr;
	-ms-grid-rows: auto auto;
	grid-template-rows: auto auto;
}

.floating-dot {
	position: absolute;
	left: -5%;
	top: 2rem;
	right: auto;
	bottom: 0%;
	display: none;
	width: 8px;
	height: 8px;
	border-radius: 20px;
	background-color: #c32026;
}

.our-firm-section {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding-right: 0rem;
	padding-left: 0rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: transparent;
	text-align: center;
}

.stat-icon {
	display: none;
	width: 50px;
	height: 50px;
	margin-right: 0.25rem;
	padding: 0px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 100px;
	background-color: transparent;
}

.stat-icon.years {
	padding-right: 3px;
	padding-left: 3px;
}

.stat-icon.offices {
	display: none;
	width: 50px;
	height: 50px;
	margin-right: 0rem;
	margin-bottom: 1rem;
	padding-right: 3px;
	padding-left: 3px;
	border-style: none;
	border-width: 2px;
	border-color: #c51b00;
	border-radius: 0%;
}

.section-heading {
	margin-top: 0px;
	margin-bottom: 1rem;
	font-family: Lato, sans-serif;
	color: #650030;
	font-size: 2.5rem;
	line-height: 2.75rem;
	font-weight: 400;
	text-align: center;
	letter-spacing: 0px;
	text-transform: none;
}

.stat-text-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.stats-cb-2 {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: auto;
	margin-right: 0rem;
	margin-bottom: 0rem;
	padding: 1.25rem 0rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	border-style: solid none;
	border-width: 1px;
	border-color: #e2e2e2 #000;
	border-radius: 0px;
	background-color: transparent;
	-webkit-transition: box-shadow 500ms ease;
	transition: box-shadow 500ms ease;
	text-decoration: none;
}

.stats-cb-2:hover {
	border-style: solid none;
	border-width: 1px;
	border-color: #e2e2e2 #000;
	background-color: transparent;
}

.stats-cb-2.three {
	display: none;
	border: 1px none #000;
}

.stats-cb-2.three:hover {
	border-style: none;
}

.stats-cb-2.four {
	margin-right: 0rem;
	padding: 1.25rem 0rem;
	border-right-style: none;
	border-bottom-style: solid;
}

.stats-cb-2.four:hover {
	border-style: solid none;
}

.stats-cb-2.two {
	margin-right: 0rem;
	margin-left: 0rem;
	padding: 1.25rem 0rem;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	border-style: solid none;
	border-width: 1px;
	border-color: #e2e2e2 #000;
}

.stats-cb-2.two:hover {
	border: 1px none #000;
}

.stats-section {
	display: none;
	width: auto;
	margin-bottom: 2rem;
	padding-right: 0rem;
	padding-left: 0rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.paragraph-3 {
	font-family: Lato, sans-serif;
	font-size: 1.2rem;
	font-weight: 300;
	text-align: center;
}

.get-involved {
	position: relative;
	padding: 2rem 0rem 0rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #fff;
	color: #fff;
}

.cb-item-overlay {
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: 0px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	height: auto;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom: 3px none #eab14a;
	background-color: rgba(25, 56, 133, 0.8);
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
	-webkit-transition: all 600ms ease;
	transition: all 600ms ease;
	color: #fff;
}

.solutions-container {
	position: relative;
	z-index: 1;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
	padding-right: 20px;
	padding-left: 40px;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.overlay-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 120px;
	padding: 20px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	text-decoration: none;
}

.overlay-wrap:hover {
	background-color: rgba(101, 0, 48, 0.8);
}

.home-content-p {
	display: block;
	max-width: 1020px;
	margin-right: auto;
	margin-left: auto;
	font-size: 1.2em;
	line-height: 1.65em;
	text-align: center;
}

.cb-item-title {
	width: 280px;
	margin-top: 0px;
	margin-bottom: 0px;
	color: #fff;
	font-size: 1.5rem;
	line-height: 2rem;
}

.cb-items-inner-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 3.5rem;
	margin-bottom: 3.5rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.section-heading-2 {
	margin-top: 0px;
	margin-bottom: 1.25rem;
	font-size: 3em;
	line-height: 1.25em;
	font-weight: 400;
	text-align: center;
}

.cb-item {
	position: relative;
	overflow: hidden;
	width: 32%;
	height: 320px;
	margin-right: 5px;
	margin-bottom: 10px;
	margin-left: 5px;
	background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	box-shadow: -1px 4px 8px 0 rgba(0, 0, 0, 0.25);
}

.cb-item:hover {
	box-shadow: 0 0 20px 6px rgba(0, 0, 0, 0.25);
}

.cb-item._1 {
	background-image: url('/20181016082423/assets/images/cytonn-photography-l3MMvRYdPhc-unsplash.jpg');
	box-shadow: none;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
}

.cb-item._1:hover {
	box-shadow: none;
	-webkit-transform: scale(1.03);
	-ms-transform: scale(1.03);
	transform: scale(1.03);
}

.cb-item._2 {
	background-image: url('/20181016082423/assets/images/christina-wocintechchat-com-faEfWCdOKIg-unsplash.jpg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	box-shadow: none;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
}

.cb-item._2:hover {
	box-shadow: none;
	-webkit-transform: scale(1.03);
	-ms-transform: scale(1.03);
	transform: scale(1.03);
}

.cb-item._3 {
	background-image: url('/20181016082423/assets/images/cytonn-photography-l3MMvRYdPhc-unsplash.jpg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	box-shadow: none;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
}

.cb-item._3:hover {
	box-shadow: none;
	-webkit-transform: scale(1.03);
	-ms-transform: scale(1.03);
	transform: scale(1.03);
}

.icon-wrap-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	width: 6rem;
	height: 6rem;
	margin-bottom: 0.5rem;
	padding-right: 20px;
	padding-left: 20px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 50px;
	background-color: #193885;
}

.icon-link-wrapper-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 25%;
	height: auto;
	padding: 0rem 2rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border: 1px solid transparent;
	background-color: transparent;
	text-decoration: none;
}

.paragraph-4 {
	margin-bottom: 0px;
	padding-top: 0rem;
	padding-bottom: 0rem;
	font-family: Lato, sans-serif;
	color: #525252;
	font-size: 1rem;
	text-align: center;
	text-decoration: none;
}

.icon-wrapper-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: 1450px;
	margin-top: 0rem;
	margin-bottom: 0rem;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.bold-text {
	font-weight: 400;
}

.heading-68 {
	width: 250px;
	margin-top: 0rem;
	margin-bottom: 0rem;
	font-family: Lato, sans-serif;
	color: #193885;
	font-size: 2rem;
	line-height: 2.8rem;
	font-weight: 400;
	text-decoration: none;
}

.logo-name {
	width: 30%;
	padding-left: 10px;
	text-decoration: none;
}

.bold-text-2 {
	opacity: 1;
	color: #193885;
	font-size: 1rem;
	font-weight: 700;
}

.div-block-8 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 40%;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.link-5 {
	opacity: 0.75;
	color: #193885 !important;
	text-decoration: none !important;
	border-bottom: 1px solid transparent;
}

.link-5:hover {
	opacity: 1;
}

.logo-wrapper {
	position: relative;
	width: 165px;
	max-width: 200px;
	min-width: auto;
}

.div-block-75 {
	display: none;
}

.header-search-wrap {
	position: relative;
	margin-right: 1rem;
	margin-bottom: 0px;
	margin-left: 2rem;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
}

.header-search-form-2 {
	position: relative;
	width: 180px;
	background-color: transparent;
}

.form-field-label.hidden {
	position: absolute;
	z-index: 0;
}

.header-search-form-field {
	position: relative;
	width: 80%;
	height: 34px;
	margin-bottom: 0px;
	float: left;
	border: 1px none #d8d2bc;
	border-radius: 0px;
	font-family: Lato, sans-serif;
	font-size: 0.9rem;
	font-weight: 400;
}

.header-search-form-field:focus {
	border-color: #d8d2bc;
}

.button-2 {
	display: inline-block;
	margin-top: 0.5em;
	margin-bottom: 0.5em;
	padding: 0.5em 1.3em;
	background-color: #8d3e3c;
	-webkit-transition: background-color 200ms ease, color 200ms ease;
	transition: background-color 200ms ease, color 200ms ease;
	color: #fff;
	font-weight: 400;
	text-align: center;
	letter-spacing: 0.05em;
	text-decoration: none;
}

.button-2:hover {
	background-color: #690100;
	color: #fff;
}

.button-2.header-search-button {
	position: relative;
	width: 20%;
	height: 34px;
	max-width: 34px;
	margin-top: 0em;
	margin-bottom: 0em;
	padding: 0em;
	float: left;
	background-color: transparent;
	background-image: url('/20181016082423/assets/images/search_wht.svg');
	background-position: 50% 50%;
	background-size: 50% 50%;
	background-repeat: no-repeat;
	opacity: 0.75;
	-webkit-transition: background-color 200ms ease, color 200ms ease, all 200ms ease;
	transition: background-color 200ms ease, color 200ms ease, all 200ms ease;
	color: transparent;
}

.button-2.header-search-button:hover {
	background-image: url('/20181016082423/assets/images/search_wht.svg');
	background-position: 50% 50%;
	background-size: 50% 50%;
	background-repeat: no-repeat;
	opacity: 1;
}

.div-block-76 {
	position: absolute;
	top: 0.25rem;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 400px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	z-index: 3000;
	text-decoration: none;
}

.div-block-77 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.heading-69 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 0rem;
	font-family: Lato, sans-serif;
	color: #fff;
	font-size: 3.8rem;
	font-weight: 400;
}

.div-block-78 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 45%;
	margin-left: 7rem;
	padding-top: 12rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.paragraph-5 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	font-family: Lato, sans-serif;
	color: #fff;
	font-size: 2rem;
	line-height: 2.6rem;
	font-weight: 300;
}

.paragraph-6 {
	font-family: Lato, sans-serif;
	color: #525252;
}

.paragraph-7 {
	font-family: Lato, sans-serif;
}

.paragraph-8 {
	font-family: Lato, sans-serif;
}

.paragraph-9 {
	font-family: Lato, sans-serif;
}

.paragraph-10 {
	font-family: Lato, sans-serif;
}

.paragraph-11 {
	font-family: Lato, sans-serif;
}

.paragraph-12 {
	font-family: Lato, sans-serif;
}

.heading-70 {
	font-family: Lato, sans-serif;
}

.heading-71 {
	font-family: Lato, sans-serif;
	color: #193885;
}

.paragraph-13 {
	font-family: Lato, sans-serif;
}

.field-label {
	font-family: Lato, sans-serif;
}

.text-field {
	font-family: Lato, sans-serif;
}

.text-field-2 {
	font-family: Lato, sans-serif;
}

.field-label-2 {
	font-family: Lato, sans-serif;
}

.field-label-3 {
	font-family: Lato, sans-serif;
}

.checkbox-label {
	font-family: Lato, sans-serif;
}

.radio-button-label {
	font-family: Lato, sans-serif;
}

.field-label-4 {
	font-family: Lato, sans-serif;
}

.select-field {
	font-family: Lato, sans-serif;
}

.field-label-5 {
	font-family: Lato, sans-serif;
}

.textarea {
	font-family: Lato, sans-serif;
}

.field-label-6 {
	font-family: Lato, sans-serif;
}

.paragraph-14 {
	display: none;
	font-family: Lato, sans-serif;
}

.paragraph-15 {
	font-family: Lato, sans-serif;
}

.paragraph-16 {
	font-family: Lato, sans-serif;
}

.heading-72 {
	font-family: Lato, sans-serif;
}

.text-block-5 {
	font-family: Lato, sans-serif;
	font-size: 1.2rem;
	font-weight: 300;
}

html.w-mod-js *[data-ix="slide-caption-load"] {
	opacity: 0;
	-webkit-transform: translate(200px, 0px);
	-ms-transform: translate(200px, 0px);
	transform: translate(200px, 0px);
}

html.w-mod-js *[data-ix="cb-1-scroll-load"] {
	opacity: 0;
	-webkit-transform: translate(-200px, 0px);
	-ms-transform: translate(-200px, 0px);
	transform: translate(-200px, 0px);
}

html.w-mod-js *[data-ix="cb-2-scroll-load"] {
	opacity: 0;
	-webkit-transform: translate(200px, 0px);
	-ms-transform: translate(200px, 0px);
	transform: translate(200px, 0px);
}

@media screen and (max-width: 991px) {
	.button.court-search-button {
		min-width: 40px;
	}

	.main-content-wrapper {
		width: 100%;
	}

	.container {
		max-width: 991px;
	}

	.container.inside-pg-content-container {
		max-width: 991px;
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.container.top-cbs-container {
		max-width: 991px;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.container.page-title-container {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.left-nav-col {
		display: none;
	}

	.inside-content-column {
		width: 100%;
		margin-left: 0rem;
	}

	.inside-content-column.no-sidebars-content-column {
		width: 100%;
		margin-right: 0%;
		margin-left: 0%;
	}

	.breadcrumbs-wrapper {
		line-height: 1.5rem;
	}

	.breadcrumb-link {
		margin-top: 0.25rem;
	}

	.breadcrumb-current {
		margin-top: 0.25rem;
	}

	.content-wrapper {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.right-side-col {
		width: 100%;
		margin-left: 0rem;
	}

	.brand-2 {
		width: 16%;
		height: 100px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.nav-menu-2 {
		position: absolute;
		right: 0px;
		width: 90%;
		margin-right: 0px;
		margin-left: auto;
		padding-right: 0%;
		padding-left: 0px;
		background-color: #650030;
	}

	.main-nav-link {
		margin-left: 0rem;
		border-bottom: 1px solid rgba(0, 0, 0, 0.2);
		border-left-width: 4px;
		background-color: #650030;
		box-shadow: none;
		color: #fff;
		text-align: left;
	}

	.main-nav-link:hover {
		background-color: #501818;
		box-shadow: none;
		color: #fff;
	}

	.main-nav-link.w--current {
		margin-left: 0rem;
		border-left-width: 4px;
		border-left-color: #cec1b7;
		background-color: #650030;
		box-shadow: none;
		color: #fff;
	}

	.main-nav-link.w--current:hover {
		color: #fff;
	}

	.main-nav-link.mobile-duplicate {
		display: block;
		width: 88%;
		margin-right: 0px;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-bottom-style: none;
	}

	.dropdown-wrapper {
		display: none;
	}

	.dropdown-list {
		left: 100%;
		top: auto;
		background-color: #650030;
		color: #fff;
	}

	.dropdown-link {
		color: #fff;
	}

	.rotator-section {
		padding-top: 160px;
		opacity: 1;
	}

	.rotator {
		display: block;
		height: 400px;
	}

	.slideshow-arrow {
		right: 1rem;
		bottom: 1rem;
		width: 2.25rem;
		height: 2.25rem;
	}

	.slideshow-arrow.left-arrow {
		margin-right: 3rem;
	}

	.slidenav {
		display: block;
	}

	.slide-caption-wrapper {
		position: absolute;
		top: 0px;
		display: block;
		width: 38%;
		margin-right: auto;
		padding: 1rem 1.1rem 0rem;
		background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(25, 56, 133, 0.3)), to(rgba(25, 56, 133, 0.3))), -webkit-gradient(linear, left top, left bottom, from(rgba(253, 253, 248, 0.2)), to(rgba(253, 253, 248, 0.2)));
		background-image: linear-gradient(180deg, rgba(25, 56, 133, 0.3), rgba(25, 56, 133, 0.3)), linear-gradient(180deg, rgba(253, 253, 248, 0.2), rgba(253, 253, 248, 0.2));
	}

	.slide-caption-header {
		width: auto;
		max-width: 310px;
		min-width: 232px;
		font-size: 1.45rem;
		line-height: 1.35em;
	}

	.slide-caption-paragraph {
		overflow: hidden;
		width: 100%;
		max-height: 170px;
		font-size: 0.9rem;
		line-height: 1.5em;
	}

	.arrow-icon {
		display: block;
		margin-right: auto;
		margin-left: auto;
		color: #fff;
	}

	.slide {
		width: 100%;
		height: 100%;
		background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 243, 0.51)), to(rgba(255, 255, 243, 0.51)));
		background-image: linear-gradient(180deg, rgba(255, 255, 243, 0.51), rgba(255, 255, 243, 0.51));
		background-position: 0px 0px;
		background-size: auto;
		opacity: 1;
	}

	.slide.slide2 {
		height: 400px;
	}

	.slide.slide3 {
		height: 400px;
	}

	.cb-static {
		width: 100%;
	}

	.cb-static.footer-cb-1 {
		display: inline-block;
		width: auto;
		float: left;
		text-align: left;
	}

	.cb-static.footer-cb-2 {
		width: 100%;
		clear: both;
	}

	.cb-static.footer-cb-3 {
		display: block;
		width: auto;
		margin-right: 1rem;
		margin-bottom: 2rem;
		float: right;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		text-align: center;
	}

	.lower-content-wrapper {
		width: 75%;
	}

	.signoff-link {
		display: inline-block;
		margin-bottom: 1rem;
	}

	.menu-button {
		display: inline-block;
		padding: 0.5rem 1.13rem 0.72rem 1.13em;
		-webkit-transition: all 200ms ease;
		transition: all 200ms ease;
		color: #312f2f;
		text-align: center;
		margin-top: -4px;
	}

	.menu-button:hover {
		color: #612323;
	}

	.menu-icon {
		font-size: 2rem;
		line-height: 2rem;
	}

	.menu-text {
		font-size: 0.75rem;
		line-height: 0.75rem;
	}

	.mobile-link-label {
		display: inline-block;
		margin-bottom: 0rem;
	}

	.mobile-menu-arrow {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 12%;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-left: 1px solid rgba(0, 0, 0, 0.2);
		background-color: #650030;
	}

	.mobile-menu-arrow:hover {
		background-color: rgba(0, 0, 0, 0.25);
	}

	.social-media-link-block.footer-socmed-link-block {
		display: block;
		margin-right: 0rem;
		margin-left: 0.75rem;
	}

	.social-media-link-block.footer-socmed-link-block {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-right: 0.5rem;
		margin-left: 0rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.footer-links-wrapper {
		max-width: none;
		margin-bottom: 1rem;
	}

	.two-part-mobile-nav-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-bottom: 1px solid rgba(0, 0, 0, 0.2);
		background-color: #650030;
	}

	.search-dd-button.mobile-search-dd-button {
		display: block;
		width: 4rem;
		padding-top: 1rem;
		padding-bottom: 1rem;
		float: right;
		background-image: url('/20181016082423/assets/images/mag-glass-charcoal.svg');
		background-position: 50% 50%;
		background-size: 20px 20px;
		background-repeat: no-repeat;
	}

	.search-dd-button.mobile-search-dd-button:hover {
		background-image: url('/20181016082423/assets/images/mag-glass-light-blue.svg');
		background-size: 20px 20px;
	}

	.search-dd-button.mobile-search-dd-button._1 {
		display: none;
		opacity: 1;
	}

	.header-search-form-wrapper.search-top-wrapper {
		padding-top: 0.25rem;
	}

	.mobile-menu-search-wrapper {
		position: absolute;
		left: auto;
		top: 3rem;
		right: 0px;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 60%;
		float: right;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.footer-heading.social-heading {
		display: inline-block;
		margin-top: 4px;
		margin-bottom: 0rem;
		float: left;
	}

	.cb-right {
		width: 50%;
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.header-flex-container {
		z-index: auto;
		margin-right: 0rem;
		margin-left: 0rem;
		padding: 0rem 1rem 0rem 0rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.header-container {
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.inside-page-banner-section {
		background-position: 50% 45%;
		background-size: cover;
		background-repeat: no-repeat;
	}

	.get-acrobat-link {
		margin-left: 1rem;
		text-align: left;
	}

	.slide-image {
		width: 100%;
	}

	.slide-image.slide-image-1 {
		display: block;
		width: 100%;
		height: 400px;
		background-position: 0px 0px, 50% 50%;
		background-size: auto, cover;
		background-repeat: repeat, no-repeat;
	}

	.cb-heading {
		font-size: 1.75rem;
		line-height: 1.3em;
	}

	.cb-heading.court-finder-head {
		font-size: 3vw;
	}

	.cb-heading.lower-cb-heading {
		width: 100%;
		font-size: 2rem;
	}

	.photo-bg-section {
		background-image: url('/20181016082423/assets/images/iowa-judicial-branch-footer-img-tablet.jpg'), -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1))), url('/20181016082423/assets/images/IMG_3401-footer.jpg');
		background-image: url('/20181016082423/assets/images/iowa-judicial-branch-footer-img-tablet.jpg'), linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('/20181016082423/assets/images/IMG_3401-footer.jpg');
		background-position: 50% 50%, 0px 0px, 50% 0%;
		background-size: auto, auto, cover;
		background-repeat: repeat, repeat, no-repeat;
		background-attachment: scroll, scroll, fixed;
	}

	.court-finder-form-wrapper {
		width: 35%;
		margin-left: 1.5rem;
	}

	.cb-left {
		width: 50%;
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.acrobat-reader-image {
		text-align: left;
	}

	.footer-info-wrap {
		display: block;
	}

	.more-link {
		margin-top: 0rem;
	}

	.page-title.no-sidebars-page-title {
		margin-right: 0%;
		margin-left: 0%;
	}

	.page-title-container {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.page-title-left-spacer {
		display: none;
	}

	.fake-sv-image-example {
		height: 350px;
	}

	.logo-full {
		height: 100px;
	}

	.main-nav-link-2 {
		display: block;
		margin-left: 0rem;
		border-bottom: 1px solid rgba(0, 0, 0, 0.2);
		border-left-width: 4px;
		background-color: #650030;
		box-shadow: none;
		color: #fff;
		text-align: left;
	}

	.main-nav-link-2:hover {
		background-color: rgba(0, 0, 0, 0.25);
		box-shadow: none;
		color: #b7dad5;
	}

	.main-nav-link-2.w--current {
		border-left-width: 4px;
		border-left-color: #6cb1b4;
		background-color: rgba(0, 0, 0, 0.25);
		box-shadow: none;
		color: #fff;
	}

	.main-nav-link-2.w--current:hover {
		color: #fff;
	}

	.button-login {
		width: auto;
		height: 2rem;
		margin-top: 10px;
		margin-right: 2rem;
	}

	.heading-65 {
		font-size: 0.9rem;
		line-height: 1.2em;
	}

	.div-block-3 {
		line-height: 1em;
	}

	.link {
		font-size: 1.2rem;
	}

	.button-login-wrapper {
		top: 0px;
		width: 25%;
	}

	.div-block-4 {
		width: 200px;
		margin-bottom: 0.25rem;
	}

	.link-block {
		margin-right: 20px;
	}

	.social-media-wrapper.footer-socmed-wrapper {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.social-media-wrapper.footer-socmed-wrapper.social-2 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.div-block-6 {
		right: 140px;
		width: 30%;
		padding-top: 0rem;
		padding-bottom: 4rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.div-block-7 {
		margin-bottom: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.footer-nav-item {
		margin-bottom: 0.5rem;
		text-align: left;
	}

	.certifications-icons {
		margin-bottom: 0rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.footer-left-content-wrapper {
		width: 100%;
		padding-bottom: 1rem;
		-webkit-justify-content: space-around;
		-ms-flex-pack: distribute;
		justify-content: space-around;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-bottom: 1px none rgba(255, 248, 225, 0.25);
	}

	.footer-box {
		left: 0%;
		width: 100%;
		margin-left: 0px;
		padding: 1rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.footer-box.quick-links-section {
		padding-right: 3rem;
		padding-bottom: 0rem;
		padding-left: 3rem;
	}

	.footer-box.quick-links-section {
		padding-right: 3rem;
		padding-bottom: 0rem;
		padding-left: 3rem;
	}

	.footer-wrapper {
		max-width: 991px;
	}

	.footer-wrapper.signoff-container {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-right: 3rem;
		padding-left: 3rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.footer-wrapper.signoff-container {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-right: 3rem;
		padding-left: 3rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.cb-static-2.footer-logo-left {
		width: 50%;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.cb-static-2.footer-cb-left {
		width: 25%;
		margin-right: 2rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.cb-static-2.footer-cb-left.last-footer-cb {
		margin-right: 0rem;
	}

	.signoff-left-wrapper {
		width: 100%;
		margin-bottom: 1rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.footer-signoff-link {
		opacity: 1;
	}

	.footer-signoff-link.first-signoff-link {
		opacity: 1;
	}

	.equal-housing-lender-img {
		margin-right: 1rem;
		margin-bottom: 0rem;
		margin-left: 0px;
	}

	.cb-heading-2.footer-heading {
		font-size: 1rem;
		line-height: 1.5rem;
	}

	.copyright-paragraph-2 {
		margin-top: 0rem;
		margin-right: 0.5rem;
		opacity: 1;
	}

	.footer-icon-block {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.footer-right-content-wrap {
		width: 100%;
		padding-top: 1rem;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.footer-content-section {
		padding-right: 3rem;
		padding-left: 3rem;
	}

	.member-fdic-img {
		margin-right: 10px;
		margin-left: 10px;
	}

	.footer-nav-list {
		margin-bottom: 0rem;
		padding-left: 0px;
	}

	.div-block-69 {
		width: 260px;
		height: auto;
	}

	.text-block {
		font-size: 1rem;
	}

	.text-wrapper {
		height: auto;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.icon-wrap {
		width: 4rem;
		height: 4rem;
		margin-bottom: 0.5rem;
	}

	.icon-img {
		width: 6rem;
	}

	.h3-headings {
		margin-top: 1rem;
		margin-bottom: 0rem;
		line-height: 2.25rem;
		text-align: center;
		text-indent: 0px;
	}

	.h3-headings.icon-headings {
		margin-top: 0.25rem;
		line-height: 1.5rem;
	}

	.icon-link-wrapper {
		width: 50%;
		margin-bottom: 4rem;
	}

	.icon-wrapper {
		width: 100%;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.content-box-section {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.cb-card {
		width: 49%;
		margin-bottom: 20px;
	}

	.cb-card-title {
		font-size: 1.5em;
	}

	.flexbar-images {
		display: none;
	}

	.flexbar-paragraph.mobile {
		display: none;
		margin-bottom: 40px;
	}

	.flexbar-paragraph._1 {
		margin-right: 3rem;
		margin-bottom: 1rem;
		margin-left: 3rem;
		font-size: 1.125rem;
		line-height: 1.75rem;
	}

	.projects-section-mobile {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.flexbar-col-image._1 {
		width: 50%;
		height: 400px;
	}

	.flexbar-col-image._2 {
		width: 50%;
		height: 400px;
	}

	.secondary-button1.flexbar-link-two {
		margin-left: 3rem;
	}

	.secondary-button1.flexbar-link {
		margin-left: 3rem;
	}

	.link-text-2.white-text-link {
		font-family: Lato, sans-serif;
		font-weight: 400;
	}

	.project-col-mobile {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 50%;
		height: 300px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-right: 1px none #969797;
		text-decoration: none;
	}

	.project-col-mobile._1 {
		height: 400px;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
		border-bottom: 1px solid #969797;
		border-right-style: solid;
		-webkit-transition: background-color 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
		transition: background-color 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	}

	.project-col-mobile._1:hover {
		background-color: transparent;
	}

	.project-col-mobile._2 {
		height: 400px;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
		border-bottom: 1px solid #969797;
	}

	.flexbar-heading.col {
		margin-bottom: 20px;
		font-size: 2.25rem;
		text-decoration: none;
	}

	.flexbar-heading.col._1 {
		margin-right: 0rem;
		margin-left: 3rem;
		padding-right: 0rem;
	}

	.flexbar-heading.col._2 {
		margin-left: 3rem;
	}

	.flexbar-column-wrapper {
		display: none;
	}

	.number-wrapper {
		width: auto;
		margin-bottom: 0.25rem;
	}

	.stat-number {
		padding-right: 1rem;
		padding-left: 1rem;
		font-size: 3.25rem;
	}

	.stat-content-wrapper {
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.number-wrapper-2 {
		width: 31%;
		margin-bottom: 0.25rem;
	}

	.stat-title-2 {
		font-size: 1.125rem;
	}

	.stats-cb {
		width: auto;
		padding: 0.5rem 0rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		border: 1px none #000;
	}

	.stats-cb:hover {
		border: 1px none #000;
	}

	.stats-cb.two {
		margin-right: 0rem;
		margin-left: 0rem;
		padding: 0.5rem 0rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.stats-cb.two:hover {
		border-style: none;
	}

	.stats-cb.four {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.stat-title {
		color: #335962;
		font-size: 1rem;
		text-align: center;
	}

	.paragraph-2 {
		max-width: none;
	}

	.paragraph-2.first-paragraph {
		max-width: none;
	}

	.stat-number-2 {
		padding-right: 1rem;
		padding-left: 1rem;
		font-size: 3.25rem;
	}

	.section-content {
		padding: 3rem 3rem 2rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-flex-wrap: wrap-reverse;
		-ms-flex-wrap: wrap-reverse;
		flex-wrap: wrap-reverse;
	}

	.stat-icon-2.offices {
		width: 50px;
		height: 50px;
	}

	.stat-text-wrapper-2 {
		width: 70%;
	}

	.stat-content-wrapper-2 {
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.stats-section-2 {
		width: 100%;
		margin-bottom: 2.5rem;
		padding-left: 0rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.our-firm-section {
		width: 100%;
		margin-bottom: 2rem;
		padding-right: 0rem;
	}

	.stat-icon.offices {
		width: 50px;
		height: 50px;
	}

	.stat-text-wrapper {
		width: auto;
	}

	.stats-cb-2 {
		width: 100%;
		padding: 0.5rem 0rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		border-style: solid none;
		border-width: 1px;
		border-color: #e2e2e2 #000;
	}

	.stats-cb-2:hover {
		border-style: solid none;
		border-width: 1px;
		border-color: #e2e2e2 #000;
	}

	.stats-cb-2.four {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.stats-cb-2.two {
		margin-left: 0rem;
		padding: 0.5rem 0rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.stats-section {
		width: 100%;
		margin-bottom: 2.5rem;
		padding-left: 0rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.get-involved {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.cb-item-title {
		width: 500px;
	}

	.cb-items-inner-wrap {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.cb-item {
		width: 100%;
		margin-bottom: 2rem;
	}

	.icon-wrap-2 {
		width: 4rem;
		height: 4rem;
		margin-bottom: 0.5rem;
		padding-right: 15px;
		padding-left: 15px;
	}

	.icon-link-wrapper-2 {
		width: 50%;
		margin-bottom: 4rem;
	}

	.icon-wrapper-2 {
		width: 100%;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.heading-68 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 500px;
		padding-left: 0rem;
		font-size: 2rem;
	}

	.logo-name {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 50%;
		text-decoration: none;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.div-block-8 {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.image-5 {
		font-size: 0.5rem;
		line-height: 0.5em;
	}

	.header-search-wrap {
		display: block;
	}

	.div-block-76 {
		top: 0.25rem;
		width: auto;
	}

	.heading-69 {
		font-size: 3rem;
		line-height: 3.5rem;
	}

	.div-block-78 {
		position: absolute;
		top: 0px;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-left: 2rem;
		padding-top: 4rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.paragraph-5 {
		font-size: 1.8rem;
		line-height: 2.4rem;
	}
}

@media screen and (max-width: 767px) {
	h2 {
		font-size: 1.7rem;
	}

	ul {
		padding-left: 20px;
	}

	ol {
		padding-left: 20px;
	}

	.container {
		max-width: 767px;
	}

	.container.inside-pg-content-container {
		max-width: 767px;
	}

	.container.footer-container {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.navbar {
		height: 106px;
	}

	.brand-2 {
		width: 20%;
		height: auto;
		max-width: 450px;
		margin-top: 0rem;
		margin-bottom: 0rem;
		padding-left: 0px;
		text-align: left;
	}

	.main-nav-link.w--current {
		border-left-color: #fff;
	}

	.rotator-section {
		padding-top: 150px;
	}

	.rotator {
		height: auto;
		background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 243, 0.51)), to(rgba(255, 255, 243, 0.51))), url('/20181016082423/assets/images/blu-stripes_1blu-stripes.png');
		background-image: linear-gradient(180deg, rgba(255, 255, 243, 0.51), rgba(255, 255, 243, 0.51)), url('/20181016082423/assets/images/blu-stripes_1blu-stripes.png');
		background-position: 0px 0px, 0px 0px;
		background-size: auto, 50px;
	}

	.slideshow-arrow {
		width: 2rem;
		height: 2rem;
	}

	.slideshow-arrow.left-arrow {
		width: 2rem;
		height: 2rem;
		margin-right: 2.5rem;
	}

	.slidenav {
		bottom: 58%;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.slide-caption-wrapper {
		position: static;
		display: block;
		width: 100%;
		padding-bottom: 2.5rem;
		background-color: #193885;
		background-image: none;
	}

	.arrow-icon {
		font-size: 1rem;
	}

	.slide {
		position: relative;
		background-color: transparent;
		background-image: none;
	}

	.slide.slide2 {
		height: 280px;
	}

	.slide.slide3 {
		height: 280px;
	}

	.cb-static {
		width: 100%;
	}

	.cb-static.footer-cb-1 {
		text-align: center;
	}

	.cb-static.footer-cb-2 {
		width: 100%;
	}

	.cb-static.footer-cb-3 {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.lower-content-wrapper {
		max-width: 420px;
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.menu-button {
		display: block;
		min-width: 50px;
		padding: 0.5rem;
	}

	.search-dd-button.mobile-search-dd-button {
		display: none;
		width: auto;
		min-width: 50px;
		padding: 0.5rem;
	}

	.mobile-menu-search-wrapper {
		left: auto;
		top: 0;
		right: 1rem;
		width: 30%;
		height: 100%;
		margin-top: 3.2rem;
		display: block;
	}

	.cb-right {
		width: 100%;
	}

	.intro-paragraph {
		font-size: 1.25rem;
		line-height: 1.9rem;
	}

	.header-flex-container {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: 106px;
		padding: 0rem 0.5rem 0rem 0rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.h2-heading {
		font-size: 1.5rem;
		line-height: 2.2rem;
	}

	.inside-content-wrapper {
		padding-right: 0px;
		padding-left: 0px;
	}

	.cb-header-icon.lower-cb-icon {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.slide-image {
		display: block;
		height: 280px;
		background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(25, 56, 133, 0.3)), to(rgba(25, 56, 133, 0.3))), url('/20181016082423/assets/images/97f20783-6b7e-4332-9946-afdbb405e586-CourtHouse4.webp');
		background-image: linear-gradient(180deg, rgba(25, 56, 133, 0.3), rgba(25, 56, 133, 0.3)), url('/20181016082423/assets/images/97f20783-6b7e-4332-9946-afdbb405e586-CourtHouse4.webp');
		background-position: 0px 0px, 50% 50%;
		background-size: auto, cover;
		background-repeat: repeat, no-repeat;
	}

	.slide-image.slide-image-2 {
		display: block;
		background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(25, 56, 133, 0.3)), to(rgba(25, 56, 133, 0.3))), url('/20181016082423/assets/images/34d01965-4031-4693-9edb-a61b54fe7a18-CourtHouse1.webp');
		background-image: linear-gradient(180deg, rgba(25, 56, 133, 0.3), rgba(25, 56, 133, 0.3)), url('/20181016082423/assets/images/34d01965-4031-4693-9edb-a61b54fe7a18-CourtHouse1.webp');
		background-position: 0px 0px, 50% 50%;
		background-size: auto, cover;
		background-repeat: repeat, no-repeat;
		background-attachment: scroll, scroll;
	}

	.slide-image.slide-image-1 {
		height: 280px;
	}

	.cb-heading.court-finder-head {
		margin-bottom: 1.5rem;
		font-size: 1.5rem;
	}

	.lower-content-flex-parent {
		padding-top: 4rem;
		padding-bottom: 4rem;
	}

	.court-finder-form-wrapper {
		width: 70%;
		margin-left: 0rem;
	}

	.court-finder-section {
		padding-top: 2.5rem;
		padding-bottom: 3.5rem;
	}

	.court-finder-flex-parent {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.cb-left {
		width: 100%;
	}

	.alert-text {
		font-size: 0.95rem;
	}

	.footer-info-wrap {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.page-title {
		font-size: 2rem;
	}

	.page-title-container {
		background-size: auto;
	}

	.fake-sv-image-example {
		height: 275px;
	}

	.button-login {
		margin-top: 10px;
		margin-right: 1rem;
		padding-top: 10px;
		padding-bottom: 10px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.heading-65 {
		margin-top: 0rem;
		margin-bottom: 0rem;
		font-size: 0.85rem;
		line-height: 1.1em;
	}

	.link {
		display: none;
	}

	.button-login-wrapper {
		width: 35%;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.div-block-4 {
		height: 50px;
		margin-bottom: 0rem;
	}

	.link-block {
		width: 30px;
		height: 30px;
		margin-right: 0px;
	}

	.div-block-6 {
		width: 20%;
		padding-bottom: 1rem;
	}

	.div-block-7 {
		height: 50px;
		margin-bottom: 0rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		align-items: flex-end;
	}

	.footer-nav-item {
		margin-top: 0rem;
		margin-bottom: 0rem;
	}

	.certifications-icons {
		display: block;
		width: auto;
	}

	.footer-left-content-wrapper {
		width: 100%;
		border-bottom: 1px none rgba(255, 248, 225, 0.25);
	}

	.footer-box {
		width: 100%;
		height: auto;
		padding: 0rem;
	}

	.footer-box.quick-links-section {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	.footer-box.quick-links-section {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	.footer-wrapper {
		max-width: 767px;
	}

	.footer-wrapper.signoff-container {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-right: auto;
		margin-left: auto;
		padding-top: 0.5rem;
		padding-bottom: 1rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-justify-content: space-around;
		-ms-flex-pack: distribute;
		justify-content: space-around;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		text-align: center;
	}

	.footer-wrapper.signoff-container {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-right: auto;
		margin-left: auto;
		padding-top: 0.5rem;
		padding-bottom: 1rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-justify-content: space-around;
		-ms-flex-pack: distribute;
		justify-content: space-around;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		text-align: center;
	}

	.cb-static-2 {
		width: 33.33%;
	}

	.cb-static-2.footer-logo-left {
		width: 50%;
	}

	.cb-static-2.footer-cb-left {
		width: 40%;
		margin-right: 0rem;
	}

	.cb-static-2.footer-cb-left.top-cb-static {
		margin-bottom: 2rem;
	}

	.signoff-left-wrapper {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.equal-housing-lender-img {
		margin-right: 10px;
		margin-left: 10px;
		float: none;
	}

	.footer-middle-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		margin-bottom: 1rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.copyright-paragraph-2 {
		width: 100%;
		margin-top: 1rem;
		margin-right: 0rem;
	}

	.footer-right-content-wrap {
		padding-top: 1rem;
		padding-bottom: 1rem;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-bottom: 1px none rgba(255, 248, 225, 0.25);
	}

	.footer-content-section {
		padding-right: 3rem;
		padding-left: 3rem;
	}

	.logo {
		padding-left: 0px;
	}

	.member-fdic-img {
		margin-right: 10px;
		margin-left: 10px;
		float: none;
	}

	.signoff-link-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-left: 0rem;
	}

	.footer-nav-list {
		margin-left: 0rem;
	}

	.certifications-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.icon-wrap {
		width: 4rem;
		height: 4.5rem;
	}

	.h3-headings {
		margin-top: 0px;
		margin-bottom: 0.5rem;
	}

	.h3-headings.icon-headings {
		margin-bottom: 0.5rem;
	}

	.icon-link-wrapper {
		width: 75%;
		margin-bottom: 2.5rem;
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.icon-link-wrapper.last-icon-link {
		margin-bottom: 1rem;
	}

	.content-boxes-container {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.cb-card {
		width: 100%;
		max-width: 400px;
		margin-bottom: 20px;
	}

	.flexbar-paragraph._1 {
		max-width: none;
		margin-bottom: 1.5rem;
		font-size: 1.05rem;
		line-height: 1.5rem;
	}

	.flexbar-col-image._1 {
		width: 100%;
		height: 250px;
	}

	.flexbar-col-image._2 {
		width: 100%;
		height: 250px;
	}

	.project-col-mobile {
		height: 275px;
	}

	.project-col-mobile._1 {
		width: 100%;
		height: 250px;
	}

	.project-col-mobile._2 {
		width: 100%;
		height: 250px;
	}

	.flexbar-heading {
		font-size: 2.25rem;
		line-height: 2.25rem;
	}

	.flexbar-heading.col {
		margin-bottom: 15px;
		font-size: 1.8rem;
		line-height: 2rem;
	}

	.flexbar-heading.col._1 {
		margin-bottom: 1rem;
		font-size: 2rem;
		line-height: 2.35rem;
	}

	.flexbar-heading.col._2 {
		margin-bottom: 1rem;
		font-size: 2rem;
		line-height: 2.35rem;
	}

	.number-wrapper {
		width: 42%;
	}

	.number-wrapper-2 {
		width: 42%;
	}

	.stats-cb {
		width: auto;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.stats-cb.two {
		margin-right: 0rem;
		margin-left: 0rem;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.paragraph-2 {
		font-size: 1.05rem;
		line-height: 1.5rem;
	}

	.paragraph-2.first-paragraph {
		font-size: 1.05rem;
		line-height: 1.5rem;
	}

	.section-content {
		padding: 2.5rem;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.stat-icon-2.offices {
		padding-right: 5px;
		padding-left: 5px;
	}

	.stat-text-wrapper-2 {
		width: 60%;
	}

	.stats-section-2 {
		width: 100%;
		margin-bottom: 0rem;
		padding-top: 0rem;
		padding-left: 0rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.our-firm-section {
		width: 100%;
		margin-bottom: 1rem;
	}

	.section-heading {
		font-size: 2rem;
		line-height: 2.35rem;
	}

	.stat-text-wrapper {
		width: 58%;
	}

	.stats-cb-2 {
		width: 100%;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.stats-cb-2.two {
		margin-right: 0rem;
		margin-left: 0rem;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.stats-section {
		width: 100%;
		margin-bottom: 0rem;
		padding-top: 0rem;
		padding-left: 0rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.solutions-container {
		padding-right: 20px;
		padding-left: 20px;
	}

	.cb-items-inner-wrap {
		margin-top: 2rem;
		margin-bottom: 2rem;
	}

	.section-heading-2 {
		font-size: 3em;
	}

	.cb-item {
		width: 100%;
		margin-right: 0px;
		margin-left: 0px;
	}

	.icon-wrap-2 {
		width: 4rem;
		height: 4rem;
		padding-right: 15px;
		padding-left: 15px;
	}

	.icon-link-wrapper-2 {
		width: 75%;
		margin-bottom: 2.5rem;
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.icon-link-wrapper-2.last-icon-link {
		margin-bottom: 1rem;
	}

	.heading-68 {
		padding-top: 1rem;
		padding-left: 0.5rem;
		font-size: 1.5rem;
	}

	.div-block-76 {
		top: 0rem;
		width: 70%;
	}

	.div-block-77 {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.div-block-78 {
		width: 90%;
		padding-top: 2rem;
	}
}

@media screen and (max-width: 479px) {
	h2 {
		font-size: 1.5rem;
	}

	h3 {
		font-size: 1.4rem;
	}

	.button.search-button {
		width: 30%;
		min-width: 0px;
		padding-right: 0.5rem;
		padding-left: 0.5rem;
		font-size: 0.9rem;
	}

	.main-content-wrapper {
		padding-top: 1.25rem;
	}

	.container {
		max-width: 479px;
	}

	.container.inside-pg-content-container {
		max-width: none;
	}

	.navbar {
		height: 126px;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.brand-2 {
		left: 0px;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 40%;
		height: 85px;
		max-width: none;
		margin-top: 0.125rem;
		margin-right: 0px;
		margin-left: 0px;
		float: none;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		text-align: center;
	}

	.nav-menu-2 {
		font-size: 0.9rem;
	}

	.main-nav-link.mobile-duplicate {
		width: 83%;
	}

	.rotator-section {
		padding-top: 200px;
	}

	.rotator {
		max-height: 700px;
		min-height: 300px;
	}

	.slideshow-arrow.left-arrow {
		margin-right: 2.5rem;
	}

	.slidenav {
		bottom: 65%;
	}

	.slide-caption-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-bottom: 1rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.slide-caption-header {
		line-height: 1.3em;
	}

	.slide.slide2 {
		height: 200px;
	}

	.slide.slide3 {
		height: 200px;
	}

	.cb-static {
		width: 100%;
	}

	.cb-static.footer-cb-1 {
		width: 100%;
		margin-bottom: 39px;
		float: none;
	}

	.cb-static.footer-cb-2 {
		width: 100%;
	}

	.cb-static.footer-cb-3 {
		position: relative;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
	}

	.lower-content-wrapper {
		width: 80%;
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.signoff-link {
		display: block;
	}

	.menu-button {
		margin-right: 0.5rem;
		padding-top: 0.35rem;
		padding-bottom: 0.35rem;
	}

	.menu-text {
		display: none;
	}

	.mobile-menu-arrow {
		width: 17%;
	}

	.lower-cb-paragraph {
		font-size: 1rem;
	}

	.social-media-link-block.footer-socmed-link-block {
		margin-right: 0.65rem;
		float: none;
		-webkit-box-ordinal-group: 0;
		-webkit-order: -1;
		-ms-flex-order: -1;
		order: -1;
	}

	.social-media-link-block.footer-socmed-link-block {
		margin-right: 0.25rem;
		margin-left: 0.25rem;
	}

	.footer-links-wrapper {
		margin-top: 1rem;
		margin-bottom: 3rem;
		text-align: left;
	}

	.header-search-field {
		width: 70%;
	}

	.search-dd-button.mobile-search-dd-button {
		min-width: 30px;
		margin-top: 0.5rem;
		margin-left: 1rem;
		padding: 0.75rem 0.25rem 1rem;
	}

	.header-search-form-wrapper {
		min-width: 100%;
	}

	.header-search-form-wrapper.search-top-wrapper {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.mobile-menu-search-wrapper {
		position: absolute;
		top: 3px;
		right: 0px;
		z-index: 4000;
		height: auto;
		margin-top: 1.5rem;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
		border-top: 1px none rgba(0, 0, 0, 0.1);
		background-color: transparent;
	}

	.footer-heading.social-heading {
		display: inline-block;
		float: none;
		font-size: 0.95rem;
		text-align: left;
	}

	.intro-paragraph {
		font-size: 1.15rem;
		line-height: 1.85rem;
	}

	.header-flex-container {
		height: 140px;
		padding-right: 0rem;
		padding-bottom: 0rem;
		padding-left: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		background-color: transparent;
		background-image: none;
		background-position: 0px 0px;
		background-size: auto;
	}

	.header-container {
		height: 80px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.h2-heading {
		font-size: 1.5rem;
		line-height: 2.2rem;
	}

	.inside-page-banner-section {
		height: auto;
		background-position: 50% 0%;
		background-size: cover;
	}

	.cb-header-icon.cb-1-icon {
		border-radius: 50%;
	}

	.slide-image {
		height: 200px;
	}

	.slide-image.slide-image-1 {
		height: 200px;
	}

	.cb-heading {
		font-size: 1.75rem;
		line-height: 1.3em;
	}

	.lower-content-flex-parent {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}

	.photo-bg-section {
		background-image: url('/20181016082423/assets/images/iowa-judicial-branch-footer-img-mobile.jpg'), url('/20181016082423/assets/images/iowa-judicial-branch-footer-img-tablet.jpg'), -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1))), url('/20181016082423/assets/images/IMG_3401-footer.jpg');
		background-image: url('/20181016082423/assets/images/iowa-judicial-branch-footer-img-mobile.jpg'), url('/20181016082423/assets/images/iowa-judicial-branch-footer-img-tablet.jpg'), linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('/20181016082423/assets/images/IMG_3401-footer.jpg');
		background-position: 50% 50%, 50% 50%, 0px 0px, 50% 0%;
		background-size: cover, auto, auto, cover;
		background-repeat: no-repeat, repeat, repeat, no-repeat;
		background-attachment: scroll, scroll, scroll, fixed;
	}

	.court-finder-form-wrapper {
		width: 80%;
		margin-left: 0rem;
	}

	.court-finder-flex-parent {
		text-align: center;
	}

	.alert-section {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.footer-info-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.page-title {
		font-size: 1.75rem;
	}

	.page-title-container {
		position: relative;
	}

	.fake-sv-image-example {
		height: 175px;
	}

	.logo-full {
		height: 80px;
	}

	.button-login {
		width: 100%;
		height: 2.5rem;
		margin-top: 0px;
		margin-right: 0rem;
		padding-bottom: 10px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.heading-65 {
		font-size: 0.9rem;
	}

	.button-login-wrapper {
		position: relative;
		left: auto;
		top: 90px;
		right: auto;
		display: block;
		width: 100%;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.div-block-4 {
		height: 40px;
		margin-top: 0rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.link-block {
		width: 25px;
		height: 25px;
		margin-right: 10px;
	}

	.social-media-wrapper.footer-socmed-wrapper.social-2 {
		width: 100%;
		margin-top: 0rem;
		margin-right: 0rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.social-media-wrapper.footer-socmed-wrapper.social-2.footer-icons {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.div-block-6 {
		right: auto;
		bottom: 90px;
		width: 100%;
		padding-top: 0.5rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.div-block-7 {
		height: 40px;
		margin-top: 0.25rem;
		margin-bottom: 0rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.footer-nav-item {
		margin-bottom: 0rem;
		text-align: center;
	}

	.footer-left-content-wrapper {
		padding-bottom: 0rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.footer-box {
		position: static;
		width: 100%;
		height: auto;
		padding: 0.5rem 0rem;
	}

	.footer-box.quick-links-section {
		padding: 3rem 2rem;
	}

	.footer-box.quick-links-section {
		padding: 3rem 2rem;
	}

	.footer-wrapper {
		max-width: none;
	}

	.footer-wrapper.signoff-container {
		display: block;
		margin-right: auto;
		margin-left: auto;
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.footer-wrapper.signoff-container {
		display: block;
		margin-right: auto;
		margin-left: auto;
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.footer-link-wrapper {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.footer-link-wrapper.first {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.cb-static-2.footer-logo-left {
		width: 100%;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.cb-static-2.footer-cb-left {
		width: 100%;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.footer-signoff-link {
		margin-right: 0.25rem;
		margin-left: 0.25rem;
	}

	.copyright-paragraph-2 {
		margin-right: 0rem;
		margin-bottom: 0.5rem;
	}

	.footer-icon-block {
		width: 1.5rem;
		height: 1.5rem;
	}

	.footer-right-content-wrap {
		padding-bottom: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		border-bottom-color: rgba(255, 248, 225, 0.25);
	}

	.footer-content-section {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.logo {
		width: 150px;
		max-width: 175px;
		padding-left: 0px;
	}

	.footer-divider-line {
		width: 15%;
	}

	.signoff-link-wrapper {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.footer-nav-list {
		margin-top: 0rem;
		margin-bottom: 0rem;
		margin-left: 0rem;
	}

	.icon-wrap {
		width: 3.5rem;
		height: 3.5rem;
	}

	.icon-img {
		width: 3.25rem;
	}

	.h3-headings {
		font-size: 0.9rem;
		line-height: 2.2rem;
	}

	.h3-headings.icon-headings {
		font-size: 1.1rem;
		letter-spacing: 0rem;
	}

	.icon-link-wrapper {
		width: 100%;
	}

	.icon-wrapper {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.card-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding: 20px 10px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.heading-67 {
		max-width: 250px;
		padding-right: 20px;
		padding-left: 20px;
		text-align: center;
	}

	.flexbar-paragraph._1 {
		margin-left: 1.5rem;
		font-size: 1.05rem;
		line-height: 1.5rem;
	}

	.projects-section-mobile {
		width: 100%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.flexbar-col-image._1 {
		width: 100%;
		height: 400px;
	}

	.flexbar-col-image._2 {
		width: 100%;
		height: 400px;
	}

	.secondary-button1.flexbar-link-two {
		margin-left: 1.5rem;
	}

	.secondary-button1.flexbar-link {
		margin-left: 1.5rem;
	}

	.project-col-mobile {
		width: 100%;
		height: 200px;
	}

	.project-col-mobile._1 {
		width: 100%;
		height: 400px;
	}

	.project-col-mobile._2 {
		width: 100%;
		height: 400px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.flexbar-heading.col {
		margin-bottom: 16.5px;
		font-size: 1.5rem;
	}

	.flexbar-heading.col._1 {
		margin-right: 1.5rem;
		margin-left: 1.5rem;
	}

	.flexbar-heading.col._2 {
		margin-left: 1.5rem;
	}

	.number-wrapper {
		width: auto;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.stat-number {
		margin-right: 1rem;
		padding-right: 0.25rem;
		padding-left: 0rem;
		font-size: 2.5rem;
	}

	.stat-content-wrapper {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.number-wrapper-2 {
		width: 53%;
	}

	.stat-title-2 {
		font-size: 1rem;
		line-height: 1rem;
		text-align: left;
	}

	.stats-cb {
		width: 100%;
		padding: 0rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		border-right-style: none;
	}

	.stats-cb:hover {
		border-style: none;
	}

	.stats-cb.two {
		padding-top: 0rem;
		padding-bottom: 0rem;
	}

	.stats-cb.four {
		padding-top: 0rem;
		padding-bottom: 0rem;
	}

	.stat-title {
		font-size: 1rem;
		line-height: 1rem;
		text-align: left;
	}

	.paragraph-2 {
		font-size: 1.05rem;
		line-height: 1.5rem;
		font-weight: 300;
	}

	.paragraph-2.first-paragraph {
		font-size: 1.05rem;
		line-height: 1.5rem;
	}

	.stat-number-2 {
		margin-right: 0.5rem;
		padding-right: 0.25rem;
		padding-left: 0.25rem;
		font-size: 2.55rem;
	}

	.section-content {
		padding: 2rem 1.5rem;
	}

	.stat-icon-2 {
		width: 40px;
		height: 40px;
	}

	.stat-icon-2.years {
		width: 40px;
		height: 40px;
		margin-right: 0rem;
	}

	.stat-icon-2.offices {
		width: 40px;
		height: 40px;
		margin-right: 0.25rem;
		padding-right: 3px;
		padding-left: 3px;
	}

	.stat-text-wrapper-2 {
		width: 47%;
	}

	.stat-content-wrapper-2 {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.stat-icon {
		width: 40px;
		height: 40px;
	}

	.stat-icon.years {
		width: 40px;
		height: 40px;
		margin-right: 0rem;
	}

	.stat-icon.offices {
		width: 40px;
		height: 40px;
		margin-right: 0.25rem;
		padding-right: 3px;
		padding-left: 3px;
	}

	.section-heading {
		font-size: 2rem;
		line-height: 2.35rem;
	}

	.stat-text-wrapper {
		width: auto;
	}

	.stats-cb-2 {
		width: 100%;
		padding: 0.5rem 0rem;
		border-right-style: none;
	}

	.stats-cb-2:hover {
		border-style: solid none;
	}

	.get-involved {
		padding-bottom: 0rem;
	}

	.solutions-container {
		padding-right: 0px;
		padding-left: 0px;
	}

	.cb-item-desc {
		display: none;
	}

	.cb-item-title {
		font-size: 1.4em;
	}

	.cb-items-inner-wrap {
		margin: 1.5rem 0px 1rem;
		padding-right: 10px;
		padding-left: 10px;
	}

	.section-heading-2 {
		font-size: 2em;
		text-align: left;
	}

	.cb-item {
		height: 210px;
	}

	.cb-item._1:hover {
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.cb-item._2:hover {
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.cb-item._3:hover {
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.icon-wrap-2 {
		width: 3.5rem;
		height: 3.5rem;
		padding-right: 10px;
		padding-left: 10px;
	}

	.icon-link-wrapper-2 {
		width: 100%;
	}

	.icon-wrapper-2 {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.heading-68 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 500px;
		padding-top: 0rem;
		padding-left: 0rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		font-size: 1.4rem;
		line-height: 2rem;
	}

	.logo-name {
		width: 60%;
		margin-left: -40px;
		padding-left: 0px;
	}

	.div-block-8 {
		width: 100%;
	}

	.header-search-wrap {
		margin-right: 0rem;
		margin-left: 0rem;
	}

	.div-block-76 {
		left: 1rem;
		width: 90%;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.div-block-77 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-bottom: 0.5rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.heading-69 {
		font-size: 2rem;
		line-height: 2.5rem;
	}

	.div-block-78 {
		width: 90%;
		margin-left: 1rem;
		padding-top: 0rem;
	}

	.paragraph-5 {
		font-size: 1.6rem;
		line-height: 1.8rem;
	}
}

/****************** Webflow Styles ***********************/
/****************************************************/
/* SET DEFAULT FONT SIZE */
html {
	font-size: 16px;
}
/* FONT SMOOTHING */
body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}
/* CONTROL PHONE NUMBER COLOR */
a[href^=tel] {
	text-decoration: none;
}
/* CONTROL CURSOR CLICK & DRAG HIGHLIGHT COLOR */
/* Mozilla-based browsers */
::-moz-selection {
	background-color: #00788d;
	color: #ffffff;
}
/* Safari browser */
::selection {
	background-color: #00788d;
	color: #ffffff;
}
/* COLLAPSIBLE STICKY HEADER  */
@media screen and (min-width: 992px) {
	.sticky.header-section {
		position: fixed;
		top: 0;
	}

	.sticky .header-flex-container {
		padding-top: 1rem;
		padding-bottom: 0.75rem;
	}

	.sticky .brand {
		opacity: 0;
		height: 0px;
	}

	.sticky .brand-sticky {
		height: auto;
		display: inline-block;
		opacity: 100;
	}

	.sticky .nav-menu {
		padding-right: 3rem;
	}

	.sticky .main-nav-link {
		padding-top: 1rem;
	}

	.sticky.header-section ~ .rotator-section {
		padding-top: 146px;
		/* This is 100px + the height of the collpsed nav bar. Can be margin or padding depending on preference */
	}
}

@media screen and (max-width: 1160px) and (min-width: 992px) {
	.nav-menu {
		padding-left: 3.5%;
		padding-right: 3.5%;
	}

	.main-nav-link {
		font-size: 0.95rem;
	}
}

/****************** Tablesaw ***********************/
/****************************************************/
@media (max-width: 39.9375em) {
	.tablesaw.styled tr {
		border-bottom: 2px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr td {
		border-right: 0px solid;
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr:last-child,
	.tablesaw.styled tr:last-child td:last-child {
		border-bottom: none;
	}
}

/****************** Breakpoints ********************/
/****************************************************/
[data-breakpoints] {
	display: none !important;
}

@media screen and ( min-width: 991px ) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and ( min-width: 767px ) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}

/****************** Print ***********************/
/*************************************************/
#printFooter {
	display: none;
	visibility: hidden;
}

#printHeader {
	display: none;
	visibility: hidden;
}

/*********************** Header **********************/
/*****************************************************/
.dektop.text-block-5 {
	display: inline-block;
}

.mobile.link {
	display: none;
}

.menu-text {
	text-transform: uppercase;
}

.main-nav-link.active {
	box-shadow: inset 0 -6px 0 0 #193885;
	color: #193885;
}

@media screen and ( max-width: 992px ) {
	.dektop.text-block-5 {
		display: none;
	}

	.mobile.link {
		display: inline-block;
	}
}

@media screen and ( max-width: 536px ) and (min-width: 480px) {
	.heading-68 {
		line-height: 1.8rem;
	}
}

@media screen and ( max-width: 1300px ) and (min-width: 1170px) {
	.main-nav-link {
		font-size: 0.9rem;
		padding: 0.45rem;
	}

	.div-block {
		top: 65px;
	}
}

@media screen and ( max-width: 1169px ) and (min-width: 1040px) {
	.heading-68 {
		font-size: 1.8rem;
	}

	.nav-menu-2.nav-menu-main {
		width: 550px;
	}

	.main-nav-link {
		font-size: 0.9rem;
		padding: 0.45rem;
	}

	.div-block {
		top: 80px;
	}
}

@media screen and ( max-width: 1039px ) and (min-width: 991px) {
	.nav-menu-2.nav-menu-main {
		width: 450px;
	}

	.main-nav-link {
		font-size: 0.85rem;
		padding: 0.25rem;
	}

	.div-block {
		top: 75px;
	}
}

/****************** Accesible menu **********************/
/*****************************************************/
.accesible-navigation-menu {
	display: flex;
	flex-grow: 1;
	align-items: flex-end;
	justify-content: flex-end;
}

.accesible-navigation-menu ul {
	list-style-type: none;
	margin-bottom: 0px;
}


.accesible-navigation-menu li.dropdown-wrapper .container-wrapper {
	opacity: 0;
	max-height: 0px;
	position: absolute;
	top: 100%;
	z-index: 100;
	left: 0;
	transition: max-height 100ms ease 0s, opacity 200ms ease 0s;
	height: 0;
}

.accesible-navigation-menu li.dropdown-wrapper .container-wrapper ul {
	display: none;
	padding: 0.5rem 0.3rem;
	background-color: #fff;
	max-width: 400px;
	margin: 0px;
	list-style-type: none;
	min-width: 200px;
}

.accesible-navigation-menu li.dropdown-wrapper .container-wrapper ul a {
	padding: 8px 20px;
	margin-bottom: 2px;
	padding: 0.5rem 0.5rem 0.5rem 1rem;
	transition: all 300ms ease;
	font-family: Lato, sans-serif;
	color: #312f2f;
	font-size: 1.1rem;
	font-weight: 300;
	text-decoration: none;
	display: inline-block;
	width: 100%;
	background-color: transparent;
}

.accesible-navigation-menu li.dropdown-wrapper .container-wrapper ul a:hover,
.accesible-navigation-menu li.dropdown-wrapper .container-wrapper ul a.w--current {
	background-color: #650030;
	color: #fff;
}

.accesible-navigation-menu li.dropdown-wrapper.open .container-wrapper {
	opacity: 1;
	max-height: 500px;
	height: auto;
}

.accesible-navigation-menu li.dropdown-wrapper.open .container-wrapper ul {
	display: inline-block;
}

@media (max-width: 991px) {
	.accesible-navigation-menu {
		display: none !important;
	}
}

/****************** Mobile menu **********************/
/*****************************************************/
.mobile-navigation-menu li[data-breakpoints="xl"] {
	display: flex !important;
}

.mobile-navigation-menu li[data-breakpoints="md,lg,xl"] {
	display: flex !important;
}

.mobile-navigation-menu li[data-breakpoints="xs,sm,md,lg,xl"] {
	display: flex !important;
}

@media (max-width: 991px) {
	.mobile-navigation-menu li[data-breakpoints="xl"] {
		display: none !important;
	}

	.mobile-navigation-menu li[data-breakpoints="md,lg,xl"] {
		display: flex !important;
	}

	.mobile-navigation-menu.mm-menu_position-top {
		padding-top: 168px;
	}
}

@media (max-width: 479px) {
	.mobile-navigation-menu li[data-breakpoints="xl"] {
		display: none !important;
	}

	.mobile-navigation-menu li[data-breakpoints="md,lg,xl"] {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_position-top {
		padding-top: 206px;
	}
}

@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: none !important;
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
	}
}

.mobile-navigation-menu .mm-panel,
.mobile-navigation-menu .mm-panels,
.mm-listitem:after,
.mm-navbar {
	background: #650030;
}

.mobile-navigation-menu li a.nav-a,
.mobile-navigation-menu .mm-navbar__title {
	background-color: #650030;
	box-shadow: none;
	color: #fff;
	transition: all 300ms ease;
	font-family: Lato, sans-serif;
	line-height: 1.4rem;
	font-weight: 400;
	text-decoration: none;
	padding: 0.75rem;
	text-transform: uppercase;
	justify-content: flex-start;
}

.mobile-navigation-menu .mm-navbar__title span {
	color: #fff;
	font-family: Lato, sans-serif;
	line-height: 1.4rem;
	font-weight: 400;
}

.mobile-navigation-menu li a.nav-a:hover,
.mobile-navigation-menu li.active a.nav-a {
	background-color: #501818;
	box-shadow: none;
	color: #fff;
}

.mm-listitem:after,
.mm-navbar {
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.mm-listitem:after {
	left: 0;
}

.mm-listitem,
.mm-listitem .mm-btn_next,
.mm-listitem .mm-btn_next:before,
.mm-listitem:after,
.mm-listview {
	border-color: rgba(0, 0, 0, 0.2);
}

.mm-menu .mm-listview .mm-btn_next:after,
.mm-menu .mm-btn:after,
.mm-menu .mm-btn:before {
	border-color: #fff;
}

.mm-menu .mm-listitem_selected > a:not(.mm-btn_next),
.mm-menu .mm-listitem_selected > span {
	background-color: transparent;
}

.mobile-navigation-menu > .mm-panels > .mm-panel:first-child > .mm-navbar {
	display: none;
}

/********************* Homepage *********************/
/*****************************************************/
.flexbar-paragraph p,
.paragraph-4 p {
	font-family: inherit;
	line-height: inherit;
	color: inherit;
	font-weight: inherit;
	font-size: inherit;
	letter-spacing: inherit;
}

.flexbar-paragraph p:last-child,
.paragraph-4 p:last-child {
	margin-bottom: 0px;
}

.description-wrapper p,
.description-wrapper li,
.description-wrapper a,
.description-wrapper td,
.description-wrapper h1,
.description-wrapper h2,
.description-wrapper h3,
.description-wrapper h4,
.description-wrapper h5,
.description-wrapper h6 {
	color: #fff !important;
	text-decoration: none !important;
	border-bottom: 0px !important;
}

.flexbar-paragraph p,
.flexbar-paragraph li,
.flexbar-paragraph a,
.flexbar-paragraph td,
.flexbar-paragraph h1,
.flexbar-paragraph h2,
.flexbar-paragraph h3,
.flexbar-paragraph h4,
.flexbar-paragraph h5,
.flexbar-paragraph h6 {
	color: #fff !important;
	text-decoration: none !important;
	border-bottom: 0px !important;
}

.cb-card-content {
	text-decoration: none;
	border-bottom: 0px !important;
}

#news_module.homepage_contentbox .news-item .cms_description,
#events_module.homepage_contentbox .event-item .cms_description,
#news_module.homepage_contentbox .news-item .cms_description p {
	color: #fff;
	font-size: 0.85rem;
	line-height: 1.2rem;
	margin-bottom: 10px;
}

#news_module.homepage_contentbox .news-item {
	margin: 0px;
	padding: 0px;
	border-bottom: 0px;
}

.fc-day-grid-event .fc-time {
	font-weight: normal;
}

.homebox .files-collection .file-item .text-block-2 {
	padding-bottom: 14px;
	padding-top: 13px;
}

/*********************** Slideshow ********************/
/*****************************************************/
.paragraph-5 p {
	font-family: inherit;
	line-height: inherit;
	color: inherit;
	font-weight: inherit;
	font-size: inherit;
	letter-spacing: inherit;
}

.paragraph-5 p,
.paragraph-5 li,
.paragraph-5 a,
.paragraph-5 td,
.paragraph-5 h1,
.paragraph-5 h2,
.paragraph-5 h3,
.paragraph-5 h4,
.paragraph-5 h5,
.paragraph-5 h6 {
	color: #fff;
}

.slide-image a {
	height: 100%;
	width: 100%;
	position: relative;
	display: inline-block;
}

.slide-link-desktop {
	height: 100%;
	width: calc(100% - 360px);
	position: absolute;
	top: 0;
	display: inline-block;
}

.div-block-69 {
	text-decoration: none;
}

.rotator-section {
	margin-top: 8px;
}

@media (max-width: 991px) {
	.slide-link-desktop {
		display: none;
	}
}

@media (max-width: 767px) {
	.rotator-section {
		margin-top: 5px;
	}
}

@media (max-width: 479px) {
	.rotator-section {
		margin-top: 45px;
	}
}


/****************** Inside Page ******************/
/*************************************************/
@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
	}

	.inside-page-section {
		padding-top: 166px;
	}
}

@media (max-width: 479px) {
	.inside-page-section {
		padding-top: 206px;
	}
}

iframe[src*="youtube.com"] {
	aspect-ratio: 16 / 9;
	height: auto;
	max-width: 100%;
}

.breadcrumbs-wrapper .breadcrumbs-list-item:last-child .breadcrumb-divider {
	display: none;
}

.left-nav-link.active {
	box-shadow: -3px 0 0 0 #193885;
	font-family: Lato, sans-serif;
	color: #193885;
}

.left-nav-link.grandchild-link.active {
	padding-left: 0.35rem;
	box-shadow: -3px 0 0 0 #193885;
}

.left-nav-link.greatgrandchild-link.active {
	box-shadow: -3px 0 0 0 #193885;
	color: #193885;
}

/*************** Right Sidebar ********************/
/*************************************************/
.right-side-col .box {
	margin-top: 1.25rem;
	border-top: 1px none rgba(0, 0, 0, 0.15);
	font-size: 0.875rem;
	line-height: 1.35rem;
}

.right-side-col .box h4,
.right-side-col .box h3 {
	margin-bottom: 0.5rem;
	font-family: Lato, sans-serif;
	color: #193885;
	font-size: 1.143rem;
	line-height: 1.743rem;
	margin-top: 0px;
}


.right-side-col .box p,
.right-side-col .box li,
.right-side-col .box td,
.right-side-col .box a,
.right-side-col .box span {
	font-size: 0.875rem;
	line-height: 1.35rem;
}

.right-side-col .box p {
	font-size: 0.95rem;
	line-height: 1.45rem;
}

.right-side-col .box td {
	vertical-align: top;
	padding-bottom: 12px;
}

.right-side-col .box .contentbox_item_image {
	width: 24px;
}

.right-side-col .box.documents td:first-child {
	padding-right: 7px;
}

.right-side-col .box.tools td:first-child img {
	margin-right: 5px;
}

.right-side-col .contentbox_item_image img {
	max-width: none;
}

@media (max-width: 991px) {
	.right-side-col .box {
		margin-left: 0px;
	}
}

/********************* Footer **********************/
/*****************************************************/
#GRIPFooterLogo {
	line-height: 11px;
	padding-top: 0px !important;
}

.mobile.link-5 {
	display: none;
}

.desktop.link-5,
.desktop.link-5:hover {
	display: inline-block;
	color: #193885;
	opacity: 0.75;
}

@media (max-width: 991px) {
	.mobile.link-5 {
		display: inline-block;
	}

	.desktop.link-5 {
		display: none;
	}
}

/****************** Inside Page Styles ***************/
/*****************************************************/
.left-sidebar a,
.main-content-wrapper a,
.homebox a,
.right-side-col a {
	border-bottom: 1px solid rgba(25, 56, 133, 0.5);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #193885;
	font-weight: 500;
	text-align: left;
	text-decoration: none;
	cursor: pointer;
}

.left-sidebar a:hover,
.main-content-wrapper a:hover,
.right-side-col a:hover {
	border-bottom-color: #fff;
	color: #650030;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: #565656;
}

h1 a,
.main-content-wrapper h1 a,
h2 a,
.main-content-wrapper h2 a,
h3 a,
.main-content-wrapper h3 a,
h4 a,
.main-content-wrapper h4 a,
h5 a,
.main-content-wrapper h5 a,
h6 a,
.main-content-wrapper h6 a {
	border-bottom: 1px solid rgba(25, 56, 133, 0.5);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Lato, sans-serif;
	color: #193885;
	text-decoration: none;
	font-weight: 400;
}

h5 a,
.main-content-wrapper h5 a,
h6 a,
.main-content-wrapper h6 a {
	font-weight: 700;
}

h1 a:hover,
.main-content-wrapper h1 a:hover,
h2 a:hover,
.main-content-wrapper h2 a:hover,
h3 a:hover,
.main-content-wrapper h3 a:hover,
h4 a:hover,
.main-content-wrapper h4 a:hover,
h5 a,
.main-content-wrapper h5 a:hover,
h6 a:hover,
.main-content-wrapper h6 a:hover {
	color: #650030;
}

li {
	margin-bottom: 0.25rem;
	font-family: Lato, sans-serif;
	color: #525252;
}

/* buttons */
a.primary,
.primary,
.cms_form_button.primary,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary,
.ui-form-buttons .ui-form-button button,
.module-search button {
	display: inline-block;
	padding: 9px 1rem 10px;
	border-style: solid;
	border-width: 1px;
	border-color: #193885;
	border-radius: 0px;
	background-color: #193885;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Lato, sans-serif;
	color: #fff;
	line-height: 1.6rem;
	font-weight: 600;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	border: 0px !important;
	text-decoration: none !important;
}

a.primary:hover,
.primary:hover,
.cms_form_button.primary:hover,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary:hover,
.ui-form-buttons .ui-form-button button:hover,
.module-search button:hover {
	background-color: #193885;
	background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0.1)), to(hsla(0, 0%, 100%, 0.1)));
	background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.1), hsla(0, 0%, 100%, 0.1));
	color: #fff;
}

a.secondary,
.secondary,
.cms_form_button.secondary,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.secondary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary {
	display: inline-block;
	padding: 9px 1rem 10px;
	border-style: solid;
	border-width: 1px;
	border-color: #193885;
	background-color: transparent;
	font-family: Lato, sans-serif;
	color: #312f2f;
	font-weight: 700;
	text-align: center;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	text-decoration: none !important;
}

a.secondary:hover,
.secondary:hover,
.cms_form_button.secondary:hover,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.secondary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover {
	border-color: #650030;
	background-color: #650030;
	background-image: none;
	color: #fff;
}

a.tertiary,
.tertiary,
.cms_form_button.tertiary,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.tertiary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary {
	display: inline-block;
	padding: 9px 1rem 10px;
	border-style: solid !important;
	border-width: 1px !important;
	border-color: #193885 !important;
	background-color: #ccccca;
	color: #312f2f;
	font-size: 1rem;
	line-height: 1.6rem;
	font-weight: 700;
	text-align: center;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	text-decoration: none !important;
}

a.tertiary:hover,
.tertiary:hover,
.cms_form_button.tertiary:hover,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.tertiary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary:hover {
	background-color: #afafae;
	color: #fff;
}

/* messages */
#message.success,
#polls .session_message.success {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	background-color: #b5eebe;
	font-family: Lato, sans-serif;
	color: #000;
}

#message.success p,
#polls .session_message.success p,
#message.success li,
#polls .session_message.success li {
	font-family: Lato, sans-serif;
	color: #000;
}

#message.error,
#polls .session_message.error {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	background-color: #f5d1d1;
	font-family: Lato, sans-serif;
	color: #000;
}

#message.error p,
#polls .session_message.error p,
#message.error li,
#polls .session_message.error li {
	font-family: Lato, sans-serif;
	color: #000;
}

#message.error p:last-child,
#message.success p:last-child {
	margin-bottom: 0;
}

/* tables */
table.styled {
	margin: 0 0 1em;
	background-color: white;
	border-spacing: 0px;
}

table.styled th,
table.styled td {
	border-right: 1px solid #ccc;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.625rem 0.625rem;
	text-align: left;
	background-color: #193885;
	color: white;
}

table.styled th {
	font-weight: normal;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.5rem 0.625rem;
	text-align: left;
	background-color: #565656;
	color: white;
}

table.styled th a,
table.styled th a:visited {
	color: inherit;
	font-weight: inherit;
	text-decoration: underline;
	border-bottom: 0px;
	background-color: transparent;
}

table.styled th a:hover {
	color: inherit;
	text-decoration: none;
}

table.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 0.96rem;
	line-height: 1.4rem;
}

table.styled.striped tr:nth-child(even) {
	background: #e3e3e3;
}

table.styled.striped tr:nth-child(odd) {
	background: white;
}

/* forms */
.content-wrapper input[type="text"],
.content-wrapper input[type="tel"],
.content-wrapper input[type="password"],
.content-wrapper input[type="email"],
.content-wrapper select,
.content-wrapper textarea,
.content-wrapper input[type="search"] {
	padding: 8px 12px;
	font-size: 14px;
	line-height: 1.42857143;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #666;
	margin-bottom: 5px;
}

.content-wrapper textarea {
	resize: vertical;
}

.content-wrapper label {
	font-weight: 400;
}

@media (max-width: 991px) {
	.content-wrapper input[type="text"],
	.content-wrapper input[type="tel"],
	.content-wrapper input[type="password"],
	.content-wrapper input[type="email"],
	.content-wrapper select,
	.content-wrapper textarea,
	.content-wrapper input[type="search"] {
		width: 100%;
	}
}

/*blockquotes*/
blockquote {
	border-left-color: #193885;
	background-color: rgba(25, 56, 133, 0.15);
	font-family: Lato, sans-serif;
}

blockquote p {
	font-size: inherit;
	line-height: inherit;
	margin-bottom: 0;
	margin-top: 0;
}

/*pagination*/
.pagination-wrapper {
	font-size: 0.9rem;
}

.pagination-wrapper .pagination-previous-page .disabled,
.pagination-wrapper .pagination-next-page .disabled {
	color: #333333;
	font-style: italic;
}

#pagination span {
	color: #333 !important;
}


/*********************** Modules ********************/
/*****************************************************/
/* ui form tags */
.ui-form-container {
	border: 0px;
}

.ui-form-container .ui-form-legend {
	background: #fff;
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	color: #333;
	font-size: 1rem;
	line-height: 1.3rem;
	font-weight: bold;
	text-transform: none;
	padding: 0px;
	border-bottom: 0px;
}

.ui-form-fieldset .ui-form-legend {
	text-transform: none;
}

.ui-form-container .ui-form-fields {
	border-bottom: 0px;
}

.ui-form-container .ui-form-fields .ui-form-field.required .ui-form-label em,
.ui-form-container .required_information em {
	color: #ee0000;
	font-family: inherit;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices {
	border: 1px solid #cccccc;
	border-radius: 0px;
	padding: 8px 12px;
	box-shadow: none;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
	padding: 0px;
}

.ui-form-container .ui-form-fields .select2-container .select2-choice {
	box-shadow: none;
	border-radius: 0px;
	background: none;
	border: 1px solid #ccc;
	height: 38px !important;
}

.ui-form-buttons {
	padding: 0px;
	width: 100%;
	text-align: center;
}

.ui-form-container .ui-form-fields .ui-form-error em {
	color: #ee0000;
	display: block;
	font-size: 0.9rem;
	font-style: normal;
	line-height: normal;
	font-weight: 500;
	margin: 0px 0px 0px 0px;
}

.ui-form-fields.ui-form-fields-payment-methods-radio .ui-form-radio label {
	display: inline-block;
	padding-left: 5px;
}

@media (max-width: 991px) {
	.ui-form-container .ui-form-fields {
		padding: 0px;
	}

	.ui-form-container .ui-form-label {
		position: static;
		padding: 0px;
		height: auto !important;
		display: block;
		width: 100%;
		left: 0px;
		text-align: left;
		top: auto;
		line-height: inherit;
	}

	.ui-form-container .ui-form-input {
		width: 100%;
	}

	.ui-form-container .ui-form-input input[type="text"],
	.ui-form-container .ui-form-input input[type="tel"],
	.ui-form-container .ui-form-input input[type="password"],
	.ui-form-container .ui-form-input input[type="email"],
	.ui-form-container .ui-form-input input[type="file"],
	.ui-form-container .ui-form-input select,
	.ui-form-container .ui-form-input textarea,
	.ui-form-container .ui-form-fields .select2-container,
	.ui-form-container .ui-form-fields .select2-container-multi,
	.ui-form-container .ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
		width: 100% !important;
		max-width: 100%;
	}
}

@media (max-width: 479px) {
	.ui-form-buttons button {
		margin-bottom: 10px;
	}
}

/* datepicker */
#ui-datepicker-div {
	font-size: 0.9rem;
}

/* search */
.search-collection > .search-node {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin: 1.5rem 0 0 0;
	padding: 1.5rem 0 0 0;
}

@media (max-width: 991px) {
	.module-search button {
		margin-top: 10px;
	}
}

/* accounts */
#account_module.form .form_container .login_field {
	width: 100%;
	max-width: 243px;
	margin-bottom: 15px;
}

#account_module.form .more_options ul {
	width: 100%;
	text-align: center;
	padding-left: 0;
}

#account_module.form .form_subheading {
	border-bottom: 1px solid rgba(92, 92, 92, 0.15);
	margin: 20px 0;
	padding-bottom: 4px;
	color: #333333;
	font-weight: bold;
}

#account_module.form .ui-form-fields {
	margin-bottom: 10px;
}

#account_module.form .ui-form-label {
	margin-bottom: 3px;
}

#account_module.form .ui-form-label em {
	color: #ee0000;
}

@media (max-width: 991px) {
	#account_module.form .form_buttons {
		text-align: left;
	}

	#account_module.form tr.required .field {
		background: none;
		padding-left: 0;
		display: block;
		width: 100%;
	}

	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: inline-block;
		width: 100%;
		text-align: left;
		padding-bottom: 5px;
	}

	#account_module.form .form_item .label label,
	#account_module.form .form_item.required .label label {
		display: inline;
	}

	#account_module.form tr.required .label:after {
		content: url('/core/public/shared/assets/images/required.gif');
		padding-left: 10px;
	}

	#account_module.form .form_item .field {
		display: block;
		padding-left: 0;
	}

	#account_module.form .form_item .field .login_field {
		max-width: 100%;
	}

	#account_module.form .more_options ul {
		text-align: left;
	}
}

@media (max-width: 479px) {
	#account_module.form .ui-form-fields input[type="text"],
	#account_module.form .ui-form-fields select {
		width: 100% !important;
	}
}

/* events */
#fullcalendar-controls #categories .category-wrapper .category {
	top: 0px;
}

#events_module.cms_list .cms_list_item .cms_title {
	width: 100%;
}

#events_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
}

#events_module.cms_list .cms_list_item .event-category-label {
	display: inline-block;
	margin-right: 4px;
}

#events_module.cms_list .cms_divider {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}


#events_module.cms_form .cms_label,
#events_module.cms_form .cms_field,
#events_module.cms_form .cms_required,
#events_module.cms_form .cms_non_required,
#events_module.cms_form .cms_td_answers {
	background-color: transparent;
	vertical-align: top;
}

#events_module.cms_form .cms_required {
	background-position: center 10px;
}

#events_module.cms_form h3 {
	margin-bottom: 0px;
}

#events_module.cms_form .cms_divider {
	margin-top: 5px !important;
	padding-top: 1px !important;
}

@media (max-width: 991px) {
	#events_module.cms_form .cms_field input[type="text"],
	#events_module.cms_form .cms_field input[type="password"],
	#events_module.cms_form .cms_field select,
	#events_module.cms_form .cms_field textarea,
	#events_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: 100% !important;
		max-width: 100%;
	}

	#events_module.cms_form .cms_label {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
	}

	#events_module.cms_form .cms_required,
	#events_module.cms_form .cms_non_required {
		width: 20px;
		display: inline;
		text-align: left;
		padding-left: 0px;
		float: left;
	}
}

/* files */
.collection--files .node--file,
.collection--categories .node--category,
.collection.collection--files {
	margin: 1.5rem 0px 0px 0px;
	padding: 1.5rem 0px 0px 0px;
	border-top: 1px solid rgba(92, 92, 92, 0.15);
}

.collection--files .node--file p,
.collection--categories .node--category p,
.collection.collection--files p {
	margin-bottom: 0px;
	margin-top: 0px;
}

.collection.collection--files .bookmark.create {
	background-color: transparent;
	color: #6b7571;
	font-weight: 400;
}

.collection.collection--files .field--file--title a,
.collection.collection--categories .field--category--title a {
	padding-left: 0px;
	margin-left: 0px;
}

.collection.collection--files .field--file--title,
.collection.collection--files .field--category--title {
	background-position: 0 6px;
}


.right-side-col .box.filter .filter-row.ui-form-buttons {
	padding: 0px;
	display: flex;
	align-items: center;
}

.right-side-col .box.filter .filter-row.ui-form-buttons .ui-form-field {
	padding: 6px;
	text-align: left;
}

.right-side-col .box.filter .filter-row > label {
	font-weight: 600;
}

.right-side-col .box.filter .filter-row .filter-row-options .filter-row-option {
	margin-bottom: 0px;
	line-height: 1.15rem;
}


/* news */
#news_module.cms_list .cms_item_divider,
#news_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1rem;
	border-top: 1px solid rgba(92, 92, 92, 0.15);
}

#news_module.cms_entity .cms_date h3 {
	margin-top: 0px;
}

#news_module.cms_list .cms_categories_row .image {
	margin-bottom: 10px;
}

.news_module.cms_category_list .cms_category_header_image {
	margin-bottom: 10px;
}

.box.archive .archive_links {
	overflow: hidden;
}

@media (max-width: 767px) {
	#news_module.cms_entity .cms_content .image {
		display: block;
		float: none;
		margin-left: 0px;
		height: auto;
		margin-bottom: 15px;
		margin-top: 15px;
	}
}

/* photo albums */
#photoalbums_module.cms_list .cms_list_item table td.cms_image {
	padding-right: 15px;
}

#photoalbums_module.cms_list .cms_list_item table td.cms_image a img {
	max-width: 150px;
}

#photoalbums_module.cms_list .cms_item_divider {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

#photoalbums_module.cms_entity .slideshow-wrapper #main-slideshow .slide-caption {
	background-color: #666;
	font-family: inherit;
	color: #ffffff;
}

@media (max-width: 991px) {
	#photoalbums_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#photoalbums_module.cms_list .cms_list_item table td.cms_image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}

/* posts */
.posts-node .posts-comments .required_information {
	color: #ee0000;
	font-style: italic;
}

.posts-collection > .node {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin: 1.5rem 0 0 0;
	padding: 1.5rem 0 0 0;
}

.posts-collection .posts-node .posts-field h2 {
	margin-top: 0px;
}

.posts-node #post-comments .post-comments-title {
	background-color: #193885;
	margin-top: 20px;
}

.posts-node .posts-comments .ui-form-fieldset legend.ui-form-legend {
	font-size: 1rem;
	background-color: #fff;
}

.posts-node #post-comments .post-comment .post-comment-author {
	font-size: 0.8rem;
}

.posts-node #post-comments .post-comment {
	margin-top: 1rem;
	padding: 0px 0px 1rem 0px;
	border-bottom: 1px solid rgba(92, 92, 92, 0.15);
}

.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-textarea textarea,
.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-input input[type="text"] {
	width: 100%;
}

#posts_module.form .form_item .ui-form-label,
#posts_module.form .form_item .required .ui-form-label {
	width: 100%;
	text-align: left;
}

.right-side-col .box.posts-blocks.posts-blocks-rss-feeds a,
.right-side-col .box.posts-blocks.posts-blocks-subscribes a {
	border-bottom: 0px;
}


.right-side-col .box.posts-blocks.posts-blocks-authors .posts-blocks-posts,
.right-side-col .box.posts-blocks.posts-blocks-authors .posts-blocks-author,
.right-side-col .box.posts-blocks.posts-blocks-categories .posts-blocks-category,
.right-side-col .box.posts-blocks.posts-blocks-popular-posts .posts-blocks-popular-post {
	padding-bottom: 7px;
}

.right-side-col .posts-blocks.posts-blocks-searches .posts-blocks-search-form input[type="text"].posts-blocks-search-field {
	height: 41px;
}

.right-side-col .posts-blocks.posts-blocks-searches .posts-blocks-search-form .posts-blocks-search-button {
	padding: 12px 10px 3px 10px !important;
	background-color: #193885 !important;
}

.right-side-col .posts-blocks .posts-blocks-date-filter form.posts-blocks-date-filter-form select:last-of-type {
	margin-bottom: 5px;
}

/* resources */

/* staff */
#staffdirectory_module.cms_list .cms_list_item .image {
	padding-right: 15px;
	width: 150px;
}

#staffdirectory_module.cms_list .cms_list_item .image a img {
	max-width: 140px;
}

#staffdirectory_module.cms_list .cms_item_divider {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

@media (max-width: 991px) {
	#staffdirectory_module.cms_list .cms_list_item .image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}

	#staffdirectory_module.cms_entity .image {
		float: none;
		margin-right: 0px;
		display: block;
	}
}

/* submission forms */
#submissionforms_module.cms_form .required_information em {
	color: #ee0000;
	font-family: inherit;
}

#submissionforms_module.cms_form .cms_required em {
	color: #ee0000;
	font-family: inherit;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding-top: 2px;
	font-weight: normal;
	font-size: 0.9rem;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span a {
	border-bottom: 0px;
}

#submissionforms_module.cms_form .cms_label div {
	display: inline;
}

#submissionforms_module.cms_form .sigPad input {
	padding: 8px 12px;
}

#submissionforms_module.cms_form .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .checkbox_wrapper .item label,
#submissionforms_module.cms_form .radio_wrapper .item label {
	margin: 0px;
	font-size: 0.9rem;
	line-height: normal;
}

#submissionforms_module.cms_form .cms_hint,
#submissionforms_module.cms_form .cms_date_hint,
#submissionforms_module.cms_form .agreement_hint,
#submissionforms_module.cms_form .cms_field_note,
#submissionforms_module.cms_form input[type="file"],
#submissionforms_module.homepage_contentbox .cms_date_hint,
#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.8rem;
	color: inherit;
}

#submissionforms_module.cms_form #form-payment-info .ui-form-field-subtitle .ui-form-label label,
#submissionforms_module.cms_form #form-payment-info .ui-form-field-subtitle .ui-form-label span {
	font-size: 100%;
	font-weight: 400;
}

#submissionforms_module.cms_form #form-payment-info .ui-form-expiration-date-fields > div:first-of-type {
	margin-bottom: 10px;
}

#submissionforms_module.cms_form #form-payment-info .ui-form-text-phone-extension {
	margin-top: 10px;
	margin-left: 0;
}

#submissionforms_module.cms_form .signature_name {
	display: block;
}

#submissionforms_module.cms_form .sigWrapper.current {
	display: block !important;
}

#submissionforms_module.cms_form .cms_label {
	font-size: 0.8571rem;
	line-height: 1.5rem;
}

#submissionforms_module.cms_form .sigPad input,
#submissionforms_module.cms_form .sigWrapper.current {
	border-color: #666;
}

@media (max-width: 767px) {
	#submissionforms_module.cms_form .cms_label {
		width: 100%;
	}
}

/* videos */
#video_module.cms_list .cms_list_item {
	border-bottom: 1px solid rgba(92, 92, 92, 0.15);
	padding-bottom: 1.5rem;
	margin-bottom: 1.5rem;
}

#video_module.cms_list_carousel .video-container .video-file-youtube iframe,
#video_module.cms_list_carousel .video-container .video-file .cms_media_container,
#video_module.cms_list_carousel .video-container .video-file video {
	background-color: #000;
	font-family: inherit;
	color: #fff;
}

#video_module.cms_list_carousel .video-container .owl-carousel .owl-nav .owl-prev,
#video_module.cms_list_carousel .video-container .owl-carousel .owl-nav .owl-next,
#video_module.cms_list_carousel .video-container .owl-carousel .owl-nav .owl-prev span,
#video_module.cms_list_carousel .video-container .owl-carousel .owl-nav .owl-next span {
	background-color: #fff;
	font-family: inherit;
	color: #000;
}

/* newsletter */
#newsletters_module.entity ul {
	margin: 0px;
	padding: 0px;
	list-style-type: none;
}

#newsletters_module.entity h3,
#newsletters_module.entity h4 {
	margin-top: 0px;
}

#newsletters_module.form .form_item .field {
	padding-bottom: 15px;
}

#newsletters_module.list .item_divider {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin: 1.5rem 0px 1.5rem 0px;
}

@media (max-width: 991px) {
	#newsletters_module.form .form_item .label,
	#newsletters_module.form .form_item.required .label {
		width: auto;
		display: inline-block;
		text-align: left;
		padding-bottom: 5px;
	}

	#newsletters_module.form .form_item.required .label {
		background-image: url('/core/public/shared/assets/images/required.gif');
		background-repeat: no-repeat;
		background-position: right center;
		padding-right: 20px;
	}

	#newsletters_module.form .form_item .label label {
		display: inline-block;
	}

	#newsletters_module.form .form_item .field {
		width: 100%;
		display: block;
		padding-left: 0;
	}

	#newsletters_module.form .form_item.required .field {
		background: transparent none;
	}

	#newsletters_module.form .form_item .field table tr td {
		vertical-align: top;
	}

	#newsletters_module.form .form_buttons {
		text-align: left;
	}

	#newsletters_module.form .form_item input[type="text"] {
		width: 100% !important;
	}
}

/* faq */
#faqs_module .cms_title h3 {
	margin-top: 0px;
}

#faqs_module .cms_list_item ul {
	padding-left: 0px;
	margin: 0px;
}

#faqs_module .cms_list_item ul li {
	padding-bottom: 7px;
}

#faqs_module.cms_list .cms_list_subitem {
	padding-bottom: 5px;
}

#faqs_module.cms_list .cms_item_divider,
#faqs_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-top: 1px solid rgba(92, 92, 92, 0.15);
}

/* gdpr */
#cookie-consent-container .consent-actions {
	width: 390px;
}

#cookie-consent-container .consent-disclaimer {
	width: calc(100% - 400px);
	margin-bottom: 35px;
}

#cookie-consent-container .consent-actions .consent-actions-container {
	flex-direction: row;
}

#cookie-consent-form-container form .consent-header img {
	float: none;
}

#cookie-consent-form-container form .consent-header {
	text-align: center;
}

#cookie-consent-container .consent-actions .consent-actions-container {
	align-items: center;
}

.consent-form-visible .navbar-container {
	display: none;
}

.consent-form-visible.mm-wrapper,
.consent-form-visible .mm-page {
	position: static;
}

#cookie-consent-container.minimized {
	bottom: 9px;
}

@media (max-width: 991px) {
	#cookie-consent-container .consent-actions {
		width: 100%;
		order: 2;
	}

	#cookie-consent-container .consent-disclaimer {
		width: 100%;
	}

	#cookie-consent-container {
		display: flex;
		flex-direction: column;
	}

	#cookie-consent-container .consent-dismiss-trigger-container {
		position: absolute;
		top: 10px;
		left: auto;
		right: 0;
	}
}

/* members */
.members-collection > .members-node > .members-node-details-container > .members-field.members-field-image {
	border-radius: 0px;
	box-shadow: none;
}

.members-collection > .members-node {
	padding-bottom: 1.5rem;
	margin-bottom: 1.5em;
	border-bottom: 1px solid rgba(92, 92, 92, 0.15);
}

.module-members .membership-levels-box .membership-level-container .membership-level-container__column--name small p {
	font-size: inherit;
	line-height: normal;
	margin-top: 0;
	margin-bottom: 0;
}

.module-members .ui-form-legend-text {
	font-size: 1.2rem !important;
	line-height: 1.5rem;
}

.module-members .ui-form-note {
	line-height: 1rem;
}

/* registrations */
.registrations-list .registration-entity .start-end-dates {
	font-size: 0.885rem;
	line-height: 1rem;
}

#registration-form table.attendees td:last-child,
#show-registration-confirmation table.attendees td:last-child {
	padding: 10px 7px 0px 7px !important;
}

/****************** Content Boxes ********************/
/*****************************************************/
/* events */

/* news */

/* files */

/* posts */
.homebox .posts-collection {
	color: #fff;
}

.homebox .posts-collection > .node {
	border-top: 0;
	margin: 0;
	padding: 0;
}

/* form */
#submissionforms_module.homepage_contentbox {
	text-align: left;
	line-height: 1.5rem;
	font-size: 1rem;
	color: #fff;
}

#submissionforms_module.homepage_contentbox table {
	width: auto;
}

#submissionforms_module.homepage_contentbox input[type="text"],
#submissionforms_module.homepage_contentbox input[type="tel"],
#submissionforms_module.homepage_contentbox input[type="email"],
#submissionforms_module.homepage_contentbox select,
#submissionforms_module.homepage_contentbox textarea {
	width: 100%;
	max-width: 100%;
	padding: 8px 12px;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #ccc;
	font-size: 0.9rem;
	margin-bottom: 10px;
}

#submissionforms_module.homepage_contentbox textarea {
	width: 100% !important;
}

#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 5px;
}

#submissionforms_module.homepage_contentbox input[type="checkbox"],
#submissionforms_module.homepage_contentbox input[type="radio"] {
	margin-right: 3px;
}

#submissionforms_module.homepage_contentbox .sigWrapper .pad {
	max-width: 100%;
}

/* video */

/* Homepage Resources list */
.resources-list {
	width: 100%;
	list-style-type: none;
	display: inline-block;
	margin: 0px;
	padding: 0px;
}

.resources-list li {
	display: inline-block;
	width: 100%;
	font-family: Lato, sans-serif;
	font-size: 1.4rem;
	line-height: 1.4rem;
	font-weight: 700;
	text-align: center;
	text-decoration: none;
	text-align: center;
	transition: all 0.3s ease, background-color 0.3s ease;
	margin-bottom: 0px;
	color: #fff !important;
}

.resources-list li:after {
	margin-bottom: 11px;
	margin-top: 10px;
	border-bottom: 2px solid #fff !important;
	content: "";
	display: block;
}

.resources-list li a {
	padding: 35px 20px;
	font-family: Lato, sans-serif;
	font-size: 1.4rem;
	font-weight: 700;
	display: inline-block;
	width: 100%;
	text-align: center;
	text-decoration: none;
	transition: all 0.3s ease, background-color 0.3s ease;
	color: #fff !important;
}

.resources-list li a:hover {
	background-color: #650030;
	-webkit-transform: scale(1.03);
	-ms-transform: scale(1.03);
	transform: scale(1.03);
	color: #fff !important;
}
